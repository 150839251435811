import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip
);

const WeightChartPrint = ({ weights }) => {
  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    tension: 0.5,
    transitions: false,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: "rgba(255, 255, 255, 1)",
        bodyColor: "rgba(0, 0, 0, 0.8)",
        borderColor: "rgba(0, 0, 0, 0.8)",
        padding: 6,
      },
    },
  };

  const data = {
    datasets: [
      {
        label: "Poids",
        data: weights,
        borderColor: "#f59e0b",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 250);
          gradient.addColorStop(0, "rgba(245, 158, 11, 0.45)");
          gradient.addColorStop(1, "rgba(245, 158, 11, 0)");
          return gradient;
        }, //background gradient color
        fill: true,
      },
    ],
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
  return <Line options={options} data={data} />;
};
export default WeightChartPrint;
