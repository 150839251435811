import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
function Data(props) {
  const currentUser = AuthService.getCurrentUser();
  const [calf, setCalf] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isDownloadLoading, setDownloadLoading] = useState(false);

  const navigate = useNavigate();
  let { id } = useParams();

  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/calfs/" + props.calfID,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setCalf(data);
        setLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/calfs/" + props.calfID,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        navigate("/");
      })
      .catch((error) => {
        console.error("There was an error deleting a calf!", error);
      });
  };
  const handleDownload = () => {
    setDownloadLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/print/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        const pdfWindow = window.open();
        pdfWindow.location.href =
          (await process.env.REACT_APP_API_BASE_URL) + data.url;
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("There was an error deleting a calf!", error);
        setDownloadLoading(false);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getCalf();
    }
  }, []);
  if (isLoading) {
    return <div></div>;
  }
  return (
    <div className="flex flex-col">
      <button
        className="mt-3 flex w-full items-center justify-center rounded-lg bg-white py-2 text-sm font-medium text-stone-700 shadow md:w-fit md:self-end md:px-3"
        onClick={() => handleDownload()}
        disabled={isDownloadLoading ? true : true}
      >
        <div
          className={
            "flex flex-row items-center justify-center " +
            (isDownloadLoading ? "" : "hidden")
          }
        >
          <svg
            className="mr-1 h-4 w-4 animate-spin text-amber-600"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="text-amber-200"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          <span>Traitement...</span>
        </div>
        <div className={isDownloadLoading ? "hidden" : "flex"}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-3 h-4 w-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>

          <span>Télécharger le rapport</span>
        </div>
      </button>
      <div className="mt-3 rounded-lg bg-white">
        <div className="ml-3 flex flex-col md:rounded-lg md:bg-stone-100 md:px-3">
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">BDTA</span>
            <span className="mr-3 text-sm text-stone-400">{calf.bdta}</span>
          </div>
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">Nom</span>
            <span className="mr-3 text-sm text-stone-400">{calf.name}</span>
          </div>
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">Sexe</span>
            <span className="mr-3 text-sm text-stone-400">
              {calf.sex === "m" ? "Mâle" : "Femelle"}
            </span>
          </div>
          <div
            className={
              "flex items-center justify-between border-b " +
              (calf.sex === "m" ? "" : "hidden")
            }
          >
            <span className="py-2 text-sm">Castration</span>
            <span className="mr-3 text-sm text-stone-400">
              {calf.castration === 0 ? "Non" : "Oui"}
            </span>
          </div>
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">Date de naissance</span>
            <span className="mr-3 text-sm text-stone-400">
              {new Date(calf.birthdate).toLocaleString("default", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">Heure de naissance</span>
            <span className="mr-3 text-sm text-stone-400">
              {calf.birthtime}
            </span>
          </div>
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">Vitalité</span>
            <span className="mr-3 text-sm text-stone-400">{calf.vitality}</span>
          </div>
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">Naissance</span>
            <span className="mr-3 text-sm text-stone-400">
              {calf.birthtype}
            </span>
          </div>
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">Apport en colostrum</span>
            <span className="mr-3 text-sm text-stone-400">
              {calf.colostrum} <i className="font-fira italic">l</i>
            </span>
          </div>
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">Heure de l'apport</span>
            <span className="mr-3 text-sm text-stone-400">
              {calf.apporttime}
            </span>
          </div>
          <div className="flex items-center justify-between border-b">
            <span className="py-2 text-sm">Ecornage</span>
            <span className="mr-3 text-sm text-stone-400">
              {calf.ecornage ? "Oui" : "Non"}
            </span>
          </div>
          <div
            className={
              "flex items-center justify-between " +
              (calf.ecornageDate == null ? "hidden" : "")
            }
          >
            <span className="py-2 text-sm">Date d'écornage</span>
            <span className="mr-3 text-sm text-stone-400">
              {new Date(calf.ecornageDate).toLocaleString("default", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>
        </div>
      </div>
      <div className="md:self-end">
        <Link to={"/modifier_veau/" + props.calfID}>
          <button className="mt-3 mr-3 w-full rounded-lg bg-amber-400 py-2 font-medium text-white md:w-fit md:px-3">
            Modifier
          </button>
        </Link>

        <button
          className=" mt-3 w-full rounded-lg bg-white py-2 font-medium text-red-500 shadow md:w-fit md:px-3"
          onClick={props.openModal}
        >
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Data;
