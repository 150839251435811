import React, { Fragment, useState, useEffect } from "react";
import {
  AreaChart,
  ComposedChart,
  Area,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useParams } from "react-router-dom";
function WeightChart(props) {
  const [GMQLine, setGMQLine] = useState([]);
  const [GMQ, setGMQ] = useState(0);
  const [isDarkMode, setIsDarkMode] = useState(
    window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
  );

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    const handleChange = (event) => setIsDarkMode(event.matches);

    darkModeMediaQuery.addEventListener("change", handleChange);
    return () => darkModeMediaQuery.removeEventListener("change", handleChange);
  }, []);
  function createGMQLine(weights) {
    if (weights.length === 0) return [];
    let line = [];
    let earliestDate = new Date(weights[0].x);
    let earliestWeight = weights[0].y;
    let latestDate = new Date(weights[0].x);
    let latestWeight = weights[0].y;
    weights.forEach((weight) => {
      if (new Date(weight.x) < earliestDate) {
        earliestWeight = weight.y;
        earliestDate = new Date(weight.x);
      }
      if (new Date(weight.x) > latestDate) {
        latestDate = new Date(weight.x);
        latestWeight = weight.y;
      }
    });
    let nbDaysBetweenDate = (latestDate - earliestDate) / (1000 * 3600 * 24);
    let lastWeight = nbDaysBetweenDate * 0.75 + earliestWeight;
    let weightDifference = latestWeight - earliestWeight;
    let valueGMQ = 0;
    if (nbDaysBetweenDate != 0) {
      valueGMQ = weightDifference / nbDaysBetweenDate;
    }
    line.push({ x: earliestDate, y: earliestWeight });
    line.push({ x: latestDate, y: lastWeight });
    setGMQ(valueGMQ);
    setGMQLine(line);
    if (props.setGMQ != undefined) {
      props.setGMQ(valueGMQ);
    }
  }
  let { id } = useParams();
  if (id === undefined) {
    id = "null";
  }
  useEffect(() => {
    createGMQLine(props.weights);
    console.log(props.weights);
  }, [props]);

  return (
    <Fragment>
      <div className="mb-3 flex w-full flex-col rounded-xl border border-gray-200 bg-white p-3  dark:border-slate-700 dark:bg-slate-900">
        <div className="mb-6">
          <span className="font-semibold ">
            Historique des quantités de lait
          </span>
        </div>
        <div className="h-80 w-full  md:px-3">
          <ResponsiveContainer width="100%" height="100%">
            <ComposedChart margin={{ top: 5, right: 0, left: 0, bottom: 5 }}>
              <defs>
                <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                  {isDarkMode ? (
                    <>
                      <stop offset="5%" stopColor="#fcd34d" stopOpacity={0.8} />
                      <stop
                        offset="95%"
                        stopColor="#172554"
                        stopOpacity={0.0}
                      />
                    </>
                  ) : (
                    <>
                      <stop offset="5%" stopColor="#fcd34d" stopOpacity={0.8} />
                      <stop
                        offset="95%"
                        stopColor="#bfdbfe"
                        stopOpacity={0.0}
                      />
                    </>
                  )}
                </linearGradient>
              </defs>

              <CartesianGrid vertical={false} opacity={0.2} />
              <XAxis
                xAxisId={"Weightx"}
                dataKey="x"
                tick={false}
                tickLine={false}
                axisLine={{ stroke: "#f1f5f9" }}
                reversed={true}
              />
              <XAxis hide={true} dataKey="x" xAxisId={"PMQx"} />
              <YAxis
                tick={{ fill: "#475569", fontSize: "12px" }}
                axisLine={false}
                tickLine={false}
              />
              <Tooltip cursor={{ fill: "transparent" }} />
              <Line
                data={GMQLine}
                dataKey="y"
                label="GMQ"
                xAxisId={"PMQx"}
                type="linear"
                stroke="#7c3aed"
                strokeWidth={2}
                dot={false}
              ></Line>
              <Area
                data={props.weights}
                dataKey="y"
                label="Poids"
                xAxisId={"Weightx"}
                type="basis"
                dot={false}
                stroke="#f59e0b"
                fill="url(#colorValue)"
                strokeWidth={2}
                radius={[7, 7, 0, 0]}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className={"flex justify-end " + (GMQ === 0 ? "hidden" : "")}>
          <span
            className={
              "rounded-md border p-1 text-sm font-semibold " +
              (GMQ < 0.75
                ? "border-rose-600 bg-rose-50 text-rose-700"
                : "border-emerald-600 bg-emerald-50 text-emerald-700")
            }
          >
            {GMQ.toFixed(3)} kg/jour
          </span>
        </div>
      </div>
    </Fragment>
  );
}

export default WeightChart;
