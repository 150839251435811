import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import WeightChart from "../components/WeightChart";
function Vaccines(props) {
  const [vaccines, setVaccines] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  let { id } = useParams();

  const getVaccine = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/vaccine/calf/" + props.calfID,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setVaccines(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };

  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getVaccine();
    }
  }, []);
  if (isLoading) {
    return <div></div>;
  }
  return (
    <div>
      <div className={vaccines.length > 0 ? "" : "hidden"}>
        <div className="mt-3 flex justify-end">
          <Link to={"/nouveau_vaccin/" + props.calfID}>
            <span className="text-amber-500 ">Ajouter un vaccin</span>
          </Link>
        </div>
        <div className="mt-3 rounded-md bg-white">
          {vaccines.map((vaccine, index) => (
            <Link
              key={vaccine.pk_vaccine}
              to={"/vaccin/" + id + "/" + vaccine.pk_vaccine}
            >
              <div
                className={
                  "ml-3 flex items-center py-1" +
                  (index === vaccines.length - 1 ? "" : " border-b")
                }
              >
                <div className="flex w-2/5 flex-col">
                  <span>{vaccine.name}</span>
                  <span className="text-sm text-stone-400">
                    {new Date(vaccine.date).toLocaleString("default", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                </div>

                <div className="m-3 flex grow justify-end">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-5 w-5 text-stone-400"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 4.5l7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
      <div
        className={
          "mt-10 flex flex-col items-center justify-center " +
          (vaccines.length === 0 ? "" : "hidden")
        }
      >
        <span className="font-semibold">Ajoutez un vaccin</span>
        <p className="mt-3 text-center text-sm text-stone-500">
          Il n'y a aucun vaccin enregistré pour ce veau. Cliquez sur "Ajouter un
          vaccin" pour ajouter un vaccin.
        </p>
        <Link to={"/nouveau_vaccin/" + props.calfID}>
          <button className="mt-3 rounded-md bg-amber-400 px-3 py-2 text-white">
            Ajouter un vaccin
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Vaccines;
