import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
import CalfMenuItem from "../components/CalfMenuItem";
import { Dialog, Transition } from "@headlessui/react";
import CreateCalfDesktop from "../components/CreateCalfDesktop";

function Home() {
  const navigate = useNavigate();
  const [listCalfs, setListCalfs] = useState([]);
  const [search, setSearch] = useState("");
  const [view, setView] = useState("");
  const [healthFilter, setHealthFilter] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [isDisplay, setDisplay] = useState(false);
  let [isCreateCalfOpen, setIsCreateCalfOpen] = useState(false);
  function closeCreateCalfModal() {
    setIsCreateCalfOpen(false);
  }

  function openCreateCalfModal(id) {
    setIsCreateCalfOpen(true);
  }
  const getCalfs = async (userId) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/calfs/user/" + userId
      );
      const jsonData = await response.json();
      setListCalfs(jsonData);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  const refreshCalfs = () => {
    const currentUser = AuthService.getCurrentUser();
    getCalfs(currentUser.id);
  };
  const handleClose = () => {
    setDisplay(!isDisplay);
  };
  const switchView = (view) => {
    setView(view);
    setHealthFilter(view);
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getCalfs(currentUser.id);
    }
  }, []);
  if (isLoading) {
    return <div></div>;
  }
  return (
    <div>
      <TopBar link=""></TopBar>
      <div className="md:flex md:flex-col md:items-center">
        <div className="md:w-[1400px] md:bg-white  md:pb-3">
          <div className={listCalfs.length === 0 ? "" : "hidden"}>
            <div className="mt-10 flex flex-col items-center justify-center">
              <img src="./empty_state.png" className="h-36 opacity-20"></img>
              <div className=" my-3">
                <span className="font-semibold">
                  Commencez par ajouter un veau
                </span>
              </div>
              <div className="mb-3">
                <p className="text-center text-sm text-stone-600">
                  La liste de vos veaux s'affichera ici. Commencer par ajouter
                  un veau en cliquant sur "Ajouter un veau"
                </p>
              </div>
              <Link to="/nouveau">
                <button className="mt-3 rounded-lg bg-amber-400 px-3 py-2 text-sm text-white md:hidden">
                  Ajouter un veau
                </button>
              </Link>

              <button
                onClick={() => openCreateCalfModal()}
                className="mt-3 hidden rounded-lg bg-amber-400 px-3 py-2 text-sm text-white md:block"
              >
                Ajouter un veau
              </button>
            </div>
          </div>
          <div className={listCalfs.length === 0 ? "hidden" : ""}>
            <div className="my-3 flex flex-col">
              <div className="flex items-center justify-between px-3 md:mb-3 md:px-0">
                <span className="text-2xl font-bold">Mon troupeau</span>
                <div className="md:hidden">
                  <Link to="/nouveau">
                    <button className="mb-3 flex rounded-md border border-amber-500 bg-amber-400 px-3 py-2 text-sm text-white shadow transition-all duration-100 hover:shadow-inner">
                      Ajouter un veau
                    </button>
                  </Link>
                </div>
                <div className="hidden md:block">
                  <button
                    onClick={() => openCreateCalfModal()}
                    className="flex rounded-md border border-amber-500 bg-amber-400 px-3 py-2 text-sm text-white shadow transition-all duration-100 hover:shadow-inner"
                  >
                    Ajouter un veau
                  </button>
                </div>
              </div>
              <div className="mb-6 hidden w-full border-b border-stone-300 md:block"></div>
              <div className="flex">
                <div className="mt-12 hidden md:flex md:w-1/5 md:flex-col md:justify-start">
                  <button
                    className={
                      "flex w-fit rounded-lg px-3 py-2 " +
                      (view == "" ? "bg-amber-100" : "")
                    }
                    onClick={() => switchView("")}
                  >
                    <div className="flex w-40">
                      <span
                        className={
                          view == ""
                            ? "font-semibold text-amber-800"
                            : "font-medium text-stone-500"
                        }
                      >
                        Tous les éléments
                      </span>
                    </div>
                    <div
                      className={
                        "ml-3 flex content-center items-center justify-center rounded-full  px-[10px] py-1 " +
                        (view == ""
                          ? "bg-amber-700 text-amber-200"
                          : "bg-stone-100 text-stone-700")
                      }
                    >
                      <span className="text-xs font-medium">
                        {listCalfs.length}
                      </span>
                    </div>
                  </button>
                  <button
                    className={
                      "flex w-fit rounded-lg px-3 py-2 " +
                      (view == "healthy" ? "bg-amber-100" : "")
                    }
                    onClick={() => switchView("healthy")}
                  >
                    <div className="flex w-40">
                      <span
                        className={
                          view == "healthy"
                            ? "font-semibold text-amber-800"
                            : "font-medium text-stone-500"
                        }
                      >
                        En Bonne santé
                      </span>
                    </div>
                    <div
                      className={
                        "ml-3 flex content-center items-center justify-center rounded-full  px-[10px] py-1 " +
                        (view == "healthy"
                          ? "bg-amber-700 text-amber-200"
                          : "bg-stone-100 text-stone-700")
                      }
                    >
                      <span className="text-xs font-medium">
                        {
                          listCalfs.filter(function (item) {
                            return item.health_status
                              .toLowerCase()
                              .includes("healthy");
                          }).length
                        }
                      </span>
                    </div>
                  </button>
                  <button
                    className={
                      "flex w-fit rounded-lg px-3 py-2 " +
                      (view == "ill" ? "bg-amber-100" : "")
                    }
                    onClick={() => switchView("ill")}
                  >
                    <div className="flex w-40">
                      <span
                        className={
                          view == "ill"
                            ? "font-semibold text-amber-800"
                            : "font-medium text-stone-500"
                        }
                      >
                        Malade
                      </span>
                    </div>
                    <div
                      className={
                        "ml-3 flex content-center items-center justify-center rounded-full  px-[10px] py-1 " +
                        (view == "ill"
                          ? "bg-amber-700 text-amber-200"
                          : "bg-stone-100 text-stone-700")
                      }
                    >
                      <span className="text-xs font-medium">
                        {
                          listCalfs.filter(function (item) {
                            return item.health_status
                              .toLowerCase()
                              .includes("ill");
                          }).length
                        }
                      </span>
                    </div>
                  </button>
                </div>
                <div className="flex w-full flex-col md:w-4/5">
                  <div className="flex flex-col px-3 md:px-0">
                    <input
                      className="w-full rounded-md border border-stone-300 px-3 py-2 md:w-64 md:self-end"
                      placeholder="Chercher"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    ></input>
                  </div>
                  <div className="my-3 border-b border-stone-200 shadow md:rounded-lg md:border">
                    <div className="flex border-y border-stone-300 bg-white  text-sm md:rounded-t-lg md:border-b md:border-t-0 md:bg-stone-50 md:py-3">
                      <div className="ml-3 w-44">
                        <span>Nom</span>
                      </div>
                      <div className="w-32">
                        <span>Status</span>
                      </div>
                    </div>
                    {listCalfs
                      .filter(function (item) {
                        return item.name.toLowerCase().includes(search);
                      })
                      .filter(function (item) {
                        return item.health_status
                          .toLowerCase()
                          .includes(healthFilter);
                      })
                      .map((item, index) => (
                        <Link
                          key={"link_" + item.pk_calf}
                          to={"/veaux/" + item.pk_calf}
                          state={{ from: "weights" }}
                        >
                          <CalfMenuItem
                            key={item.pk_calf}
                            props={{
                              calf: item,
                              index: index,
                              length: listCalfs.length,
                            }}
                          ></CalfMenuItem>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Transition appear show={isCreateCalfOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeCreateCalfModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Ajouter un nouveau veau
                  </Dialog.Title>
                  <div className="mt-2">
                    <CreateCalfDesktop
                      closeModal={closeCreateCalfModal}
                      getCalfs={refreshCalfs}
                    ></CreateCalfDesktop>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default Home;
