import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import CreateVaccineDesktop from "./CreateVaccineDesktop";
import UpdateVaccinDesktop from "./UpdateVaccineDesktop";
function VaccineListDesktop() {
  const [vaccines, setVaccines] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  let { calfId } = useParams();
  let [isUpdateVaccineOpen, setIsUpdateVaccineOpen] = useState(false);
  let [isCreateVaccineOpen, setIsCreateVaccineOpen] = useState(false);
  let [selectedVaccine, setSelectedVaccine] = useState(0);
  function closeUpdateVaccineModal() {
    setIsUpdateVaccineOpen(false);
  }

  function openUpdateVaccineModal(calfId) {
    setSelectedVaccine(calfId);
    setIsUpdateVaccineOpen(true);
  }
  function closeCreateVaccineModal() {
    setIsCreateVaccineOpen(false);
  }

  function openCreateVaccineModal() {
    setIsCreateVaccineOpen(true);
  }
  const getVaccine = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/vaccine/calf/" + calfId,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setVaccines(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };

  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getVaccine();
    }
  }, []);
  if (isLoading) {
    return <div></div>;
  }
  return (
    <div className="rounded-xl border border-stone-200 shadow">
      <div className="flex items-center justify-between rounded-t-xl border-b border-stone-200 py-3 px-3">
        <div>
          <span className="font-semibold">Vaccins</span>
        </div>
        <div>
          <button
            onClick={() => openCreateVaccineModal()}
            className="flex items-center rounded-lg border border-stone-200 px-3 py-2 text-sm text-stone-800 shadow transition-all duration-100 hover:shadow-inner"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-2 h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>

            <span>Ajouter</span>
          </button>
        </div>
      </div>
      <div className={vaccines.length > 0 ? "" : "hidden"}>
        <div className="">
          {vaccines.map((vaccine, index) => (
            <div
              key={vaccine.pk_vaccine}
              className={
                "ml-3 flex cursor-pointer items-center py-2 transition-transform duration-100 hover:-translate-x-1 " +
                (index === vaccines.length - 1 ? "" : " border-b")
              }
              onClick={() => openUpdateVaccineModal(vaccine)}
            >
              <div className="flex w-2/5 flex-col">
                <span>{vaccine.name}</span>
                <span className="text-sm text-stone-400">
                  {new Date(vaccine.date).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
              </div>

              <div className="m-3 flex grow justify-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-5 w-5 text-stone-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={
          "my-6 mx-3 flex flex-col items-center justify-center " +
          (vaccines.length === 0 ? "" : "hidden")
        }
      >
        <span className="font-semibold">Ajoutez un vaccin</span>
        <p className="mt-3 text-center text-sm text-stone-500">
          Il n'y a aucun vaccin enregistré pour ce veau. Cliquez sur "Ajouter"
          pour ajouter un vaccin.
        </p>
      </div>
      <Transition appear show={isUpdateVaccineOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeUpdateVaccineModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Modifier un vaccin
                  </Dialog.Title>
                  <div className="mt-2">
                    <UpdateVaccinDesktop
                      selectedVaccine={selectedVaccine}
                      closeModal={closeUpdateVaccineModal}
                      getVaccine={getVaccine}
                    ></UpdateVaccinDesktop>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isCreateVaccineOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeCreateVaccineModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Ajouter un vaccin
                  </Dialog.Title>
                  <div className="mt-2">
                    <CreateVaccineDesktop
                      closeModal={closeCreateVaccineModal}
                      getVaccine={getVaccine}
                      calfID={calfId}
                    ></CreateVaccineDesktop>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default VaccineListDesktop;
