import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import CalfMenuItem from "../../components/CalfMenuItem";
import { Dialog, Transition } from "@headlessui/react";
import CreateCalfDesktop from "../../components/CreateCalfDesktop";

function AdminCalfList(props) {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  if (!currentUser.roles.includes("ROLE_ADMIN")) navigate("/");

  if (props.isLoading) {
    return <div></div>;
  }
  return (
    <div>
      <div className="md:flex md:flex-col md:items-center">
        <div className="w-full md:bg-white  md:pb-3">
          <div className={props.listCalfs.length === 0 ? "hidden" : ""}>
            <div className="flex flex-col">
              <div className="flex">
                <div className="flex w-full flex-col">
                  <div className="border-b border-stone-200 shadow md:rounded-lg md:border">
                    <div className="flex border-y border-stone-300 bg-white  text-sm md:rounded-t-lg md:border-b md:border-t-0 md:bg-stone-50 md:py-3">
                      <div className="ml-3 w-44">
                        <span>Nom</span>
                      </div>
                      <div className="w-32">
                        <span>Status</span>
                      </div>
                    </div>
                    {props.listCalfs
                      .filter(function (item) {
                        return item.name.toLowerCase().includes(props.search);
                      })
                      .map((item, index) => (
                        <Link
                          key={"link_" + item.pk_calf}
                          to={"/admin/user/calf/" + item.pk_calf}
                        >
                          <CalfMenuItem
                            key={item.pk_calf}
                            props={{
                              calf: item,
                              index: index,
                              length: props.listCalfs.length,
                            }}
                          ></CalfMenuItem>
                        </Link>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AdminCalfList;
