import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Weights from "../components/Weights";
import Data from "../components/Data";
import DiseaseList from "../components/DiseaseList";
import TopBar from "../components/TopBar";
import Vaccines from "../components/Vaccines";
import WeightChart2 from "../components/veauHub/WeightChart";
import WeightChartPrint from "../components/WeightChartPrint";
function PrintCalfReport() {
  const currentUser = AuthService.getCurrentUser();
  const [calf, setCalf] = useState({});
  const [diseases, setDiseases] = useState([]);
  const [vaccines, setVaccines] = useState([]);
  const [weights, setWeights] = useState([]);
  const [weightsChart, setWeightsChart] = useState([]);
  const [GMQ, setGMQ] = useState(0);
  let { id } = useParams();

  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setCalf(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const getDisease = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(process.env.REACT_APP_API_URL + "/health/calf/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setDiseases(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const getVaccine = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/vaccine/calf/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setVaccines(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const getWeights = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(process.env.REACT_APP_API_URL + "/weight/calf/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setWeights(data);
        let chartData = [];
        data.forEach((weight) => {
          chartData.push({ x: weight.date, y: weight.weight });
        });
        setWeightsChart(chartData);
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    getCalf();
    getDisease();
    getVaccine();
    getWeights();
  }, []);

  return (
    <div className="w-[920px] bg-white p-3 shadow-lg">
      <div className="mx-6 flex w-full items-center border-b border-stone-200 py-10">
        <img src={"/logo.png"} className="mr-6 w-20"></img>
        <h1 className="appearance-none text-3xl font-bold">
          Rapport Check'veau
        </h1>
      </div>
      <div className="flex w-full">
        <div className="mb-3 ml-2 mt-6 flex w-full flex-col">
          <div className="flex">
            <div className="flex w-1/2 flex-col">
              <span className="font-semibold">Nom</span>
              <span className="text-stone-500">{calf?.name}</span>
            </div>
            <div className="flex w-1/2 flex-col">
              <span className="font-semibold">BDTA</span>
              <span className="text-stone-500">{calf?.bdta}</span>
            </div>
          </div>
          <div className="flex">
            <div className="flex w-1/2 flex-col">
              <span className="font-semibold">Sexe</span>
              <span className="text-stone-500">
                {calf?.sex === "f" ? "Femelle" : "Male"}
              </span>
            </div>
            <div className="flex w-1/2 flex-col">
              <span className="font-semibold">Date de naissance</span>
              <span className="text-stone-500">
                {new Date(calf.birthdate).toLocaleString("default", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </span>
            </div>
          </div>

          <span className="font-semibold">Ecornage</span>
          <span className="text-stone-500">
            {calf?.ecornage ? "Oui" : "Non"}
          </span>
          <span className="font-semibold">Castration</span>
          <span className="text-stone-500">
            {calf?.castration ? "Oui" : "Non"}
          </span>
          <span className="font-semibold">GMQ</span>
          <span className="text-stone-500">{GMQ?.toFixed(3)}</span>
        </div>
      </div>
      <div>
        <span className="text-sm font-medium text-stone-500">
          Donnée de naissance
        </span>
      </div>
      <div className="mt-6">
        <WeightChart2 weights={weightsChart} setGMQ={setGMQ}></WeightChart2>
      </div>
      <div className="mx-6 my-12 w-full border-b border-stone-200"></div>
      <div className="flex">
        <div className="w-1/2 pl-3">
          <span className="font-semibold">Maladies</span>
          {diseases.map((disease, index) => (
            <div key={disease.pk_health}>
              <span className="text-sm font-semibold">{disease.cause}</span>
              <div>
                <span className="text-sm text-stone-500">
                  {new Date(disease.start_date).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
                <span
                  className={
                    "mx-3 text-sm text-stone-500 " +
                    (disease.end_date ? "" : "hidden")
                  }
                >
                  -
                </span>
                <span
                  className={
                    "text-sm text-stone-500 " +
                    (disease.end_date ? "" : "hidden")
                  }
                >
                  {new Date(disease.end_date).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
              </div>
            </div>
          ))}
        </div>
        <div className="w-1/2 border-l border-stone-300 pl-12">
          <span className="font-semibold">Vaccin</span>{" "}
          <div>
            {vaccines.map((vaccin, index) => (
              <div key={vaccin.pk_vaccine}>
                <span className="text-sm font-semibold text-stone-900">
                  {vaccin.name}
                </span>
                <div className="text-sm text-stone-500">
                  <span>
                    {new Date(vaccin.date).toLocaleString("default", {
                      day: "numeric",
                      month: "long",
                      year: "numeric",
                    })}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="mx-6 my-12 w-full border-b border-stone-200"></div>
      <div></div>
    </div>
  );
}

export default PrintCalfReport;
