import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import WeightChart from "../components/WeightChart";
function CalfMenuItem(props) {
  const [status, setStatus] = useState({});
  const navigate = useNavigate();
  const calf = props.props.calf;
  console.log(calf);
  const index = props.props.index;
  const length = props.props.length;
  const getStatus = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/health/status/" + calf.pk_calf,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setStatus(data[0]);
      })
      .catch((error) => {
        console.error("There was an error getting status of a calf!", error);
      });
  };

  useEffect(() => {
    getStatus();
  }, []);
  return (
    <div>
      <div
        className={
          "flex items-center py-3 " +
          (index % 2 ? "bg-white " : "bg-stone-50 ") +
          (length === index + 1 ? "rounded-b-lg" : "")
        }
      >
        <div className="ml-3 flex w-44 flex-col">
          <span className="font-medium">{calf.name}</span>
          <span className="text-sm text-stone-400">
            {new Date(calf.birthdate).toLocaleString("default", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
        </div>
        <div>
          <div
            className={
              "rounded-full bg-emerald-100 px-2 " +
              (status.count === 0 && calf.vitality != "mort" ? "" : "hidden")
            }
          >
            <span className="text-sm font-medium text-emerald-600">
              En bonne santé
            </span>
          </div>
          <div
            className={
              "rounded-full bg-red-100 px-2 " +
              (status.count > 0 && calf.vitality != "mort" ? "" : "hidden")
            }
          >
            <span className="text-sm font-medium text-red-600">Malade</span>
          </div>
          <div
            className={
              "rounded-full bg-stone-200 px-2 " +
              (calf.vitality === "mort" ? "" : "hidden")
            }
          >
            <span className="text-sm font-medium text-stone-500">Mort</span>
          </div>
        </div>
        <div className="mr-3 flex grow justify-end">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-5 w-5 text-stone-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CalfMenuItem;
