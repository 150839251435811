import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { Line } from "react-chartjs-2";

ChartJS.register(
  TimeScale,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler
);
function createGMQLine(weights) {
  if (weights.length === 0) return [];
  let line = [];
  let earliestDate = weights[0].x;
  let earliestWeight = weights[0].y;
  let latestDate = weights[0].x;
  weights.forEach((weight) => {
    if (weight.x < earliestDate) {
      earliestWeight = weight.y;
      earliestDate = weight.x;
    }
    if (weight.x > latestDate) latestDate = weight.x;
  });
  let nbDaysBetweenDate =
    (new Date(latestDate) - new Date(earliestDate)) / (1000 * 3600 * 24);
  let lastWeight = nbDaysBetweenDate * 0.75 + earliestWeight;
  line.push({ x: earliestDate, y: earliestWeight });
  line.push({ x: latestDate, y: lastWeight });
  return line;
}

const WeightChart = ({ weights }) => {
  let line = createGMQLine(weights);
  const options = {
    scales: {
      x: {
        type: "time",
        time: {
          unit: "month",
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    tension: 0.5,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        backgroundColor: "rgba(255, 255, 255, 1)",
        bodyColor: "rgba(0, 0, 0, 0.8)",
        borderColor: "rgba(0, 0, 0, 0.8)",
        padding: 6,
      },
    },
  };

  const data = {
    datasets: [
      {
        label: "Poids",
        data: weights,
        borderWidth: 3,
        borderColor: "rgba(245, 158, 11, 1)",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 250);
          gradient.addColorStop(0, "rgba(245, 158, 11, 0.45)");
          gradient.addColorStop(1, "rgba(245, 158, 11, 0)");
          return gradient;
        }, //background gradient color
        fill: true,
      },
      {
        label: "GMQ",
        data: line,
        borderWidth: 3,
        borderColor: "rgba(79, 70, 229, 1)",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 250);
          gradient.addColorStop(0, "rgba(79, 70, 229, 0.20)");
          gradient.addColorStop(1, "rgba(79, 70, 229, 0)");
          return gradient;
        }, //background gradient color
        fill: true,
      },
    ],
    options: {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    },
  };
  return <Line options={options} data={data} />;
};
export default WeightChart;
