import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
function CreateWeightDesktop(props) {
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [weight, setWeight] = useState("");
  const navigate = useNavigate();
  let { id } = useParams();
  const handleSubmit = (event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    let parsedWeight = parseFloat(weight.replace(",", "."));

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        date: date,
        weight: parsedWeight,
        fk_calf: props.calfID,
      }),
    };
    fetch(process.env.REACT_APP_API_URL + "/weight", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        props.refreshComponent();
        props.closeModal();
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    }
  }, []);
  return (
    <div>
      <div className="">
        <form onSubmit={handleSubmit} className="flex w-full flex-col">
          <label
            htmlFor="date_input"
            className="text-sm font-medium text-stone-700"
          >
            Date de la peser
          </label>
          <input
            className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
            type="date"
            defaultValue={new Date().toISOString().substring(0, 10)}
            onChange={(e) => setDate(e.target.value)}
            placeholder="Date de la peser"
            name="date_input"
          ></input>
          <label
            htmlFor="weight_input"
            className="text-sm font-medium text-stone-700"
          >
            Poid
          </label>
          <input
            className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
            type="text"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            placeholder="Poids en Kg"
            name="weight_input"
          ></input>
          <button
            type="submit"
            className="my-3 mb-3 w-full rounded-lg bg-amber-400 p-3 font-medium text-white"
          >
            Ajouter
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreateWeightDesktop;
