import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
function NewWeight() {
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [weight, setWeight] = useState("");
  const [calf, setCalf] = useState({});
  const [weights, setWeights] = useState([]);
  const [birthWeight, setBirthWeight] = useState(0);
  const navigate = useNavigate();
  let { id } = useParams();
  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(process.env.REACT_APP_API_URL + "/calfs/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setCalf(data);
        getWeights(data.birthdate);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const getWeights = (birthdate) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(process.env.REACT_APP_API_URL + "/weight/calf/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setWeights(data);
        data.forEach((weight) => {
          if (weight.date === birthdate) setBirthWeight(weight.weight);
        });
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };

  const handleSubmit = (event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    let parsedWeight = parseFloat(weight.replace(",", "."));

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        date: date,
        weight: parsedWeight,
        fk_calf: id,
      }),
    };
    fetch(process.env.REACT_APP_API_URL + "/weight", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        navigate("/veaux/" + id);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getCalf();
    }
  }, []);
  return (
    <div>
      <TopBar link={"/veaux/" + id}></TopBar>
      <div className="mx-3 px-3 pt-2 bg-white rounded-lg">
        <div className="flex flex-col mb-3">
          <span className="font-bold text-2xl">Ajouter un poid</span>
          <p className="text-sm text-stone-400">
            Vous pouvez ajouter une nouvelle peser ici.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col w-full">
          <label
            htmlFor="date_input"
            className="text-sm font-medium text-stone-700"
          >
            Date de la peser
          </label>
          <input
            className="w-full border border-stone-300 bg-stone-100 rounded-lg mb-3 p-3 text-stone-500"
            type="date"
            defaultValue={new Date().toISOString().substring(0, 10)}
            onChange={(e) => setDate(e.target.value)}
            placeholder="Date de la peser"
            name="date_input"
          ></input>
          <label
            htmlFor="weight_input"
            className="text-sm font-medium text-stone-700"
          >
            Poid
          </label>
          <input
            className="w-full border border-stone-300 bg-stone-100 rounded-lg mb-3 p-3 text-stone-500"
            type="text"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            placeholder="Poids en Kg"
            name="weight_input"
          ></input>
          <button
            type="submit"
            className="bg-amber-400 text-white font-medium rounded-lg mb-3 w-full my-3 p-3"
          >
            Ajouter
          </button>
        </form>
      </div>
    </div>
  );
}

export default NewWeight;
