import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
function WeightPreviewDesktop(props) {
  const [weight, setWeight] = useState({});
  const [previousWeight, setPreviousWeight] = useState({});
  const [GMQ, setGMQ] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getWeight = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/weight/" + props.weightID,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setWeight(data);
        getPreviousWeight(data);
      })
      .catch((error) => {
        console.error("There was an error getting the weight", error);
      });
  };
  const getPreviousWeight = (currentWeight) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const myDate = new Date(currentWeight.date).toISOString().substring(0, 10);
    fetch(
      process.env.REACT_APP_API_URL +
        "/weight/previous/" +
        currentWeight.fk_calf +
        "/" +
        myDate,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          setLoading(false);
          return Promise.reject(error);
        }
        handlePreviousChange(currentWeight, data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error getting the previous weight", error);
      });
  };
  function handlePreviousChange(currentWeight, initialWeight) {
    setPreviousWeight(initialWeight);
    let currentDate = new Date(currentWeight.date);
    let previousDate = new Date(initialWeight.date);
    let days =
      (currentDate.getTime() - previousDate.getTime()) / (1000 * 60 * 60 * 24);
    let myGMQ = calcGMQ(initialWeight.weight, currentWeight.weight, days);
    setGMQ(myGMQ);
  }
  function calcGMQ(initialWeight, currentWeight, days) {
    return ((currentWeight - initialWeight) / days).toFixed(2);
  }
  function handleClick() {
    props.setSelectedWeight(props.weightID);
    props.setisUpdateModalOpen(true);
  }

  useEffect(() => {
    //Runs only on the first render
    getWeight();
  }, []);
  if (isLoading) {
    return <div></div>;
  }
  return (
    <div
      className="tems-center flex cursor-pointer flex-row  transition-all  duration-200 hover:translate-x-2"
      onClick={() => handleClick()}
    >
      <div className="my-3 md:my-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="ml-3 h-4 w-4"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 3v17.25m0 0c-1.472 0-2.882.265-4.185.75M12 20.25c1.472 0 2.882.265 4.185.75M18.75 4.97A48.416 48.416 0 0012 4.5c-2.291 0-4.545.16-6.75.47m13.5 0c1.01.143 2.01.317 3 .52m-3-.52l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.988 5.988 0 01-2.031.352 5.988 5.988 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L18.75 4.971zm-16.5.52c.99-.203 1.99-.377 3-.52m0 0l2.62 10.726c.122.499-.106 1.028-.589 1.202a5.989 5.989 0 01-2.031.352 5.989 5.989 0 01-2.031-.352c-.483-.174-.711-.703-.59-1.202L5.25 4.971z"
          />
        </svg>
      </div>
      <div
        className={
          "flex w-full flex-row items-center justify-start " +
          (props.index === props.listLength - 1 ? "" : " border-b")
        }
      >
        <div className="mr-6 w-12 text-right">
          <span className="ml-3 font-medium">{weight.weight.toFixed(1)}</span>
        </div>
        <div
          className={
            "rounded-full px-2 " +
            (GMQ < 0.75 && GMQ != 0
              ? "bg-red-100 text-red-600"
              : GMQ === 0
              ? "hidden"
              : "bg-emerald-100 text-emerald-600")
          }
        >
          <span className="text-sm font-medium">{GMQ}</span>
        </div>
        <div
          className={
            "rounded-full bg-blue-100 px-2 text-blue-600 " +
            (GMQ === 0 ? "" : "hidden")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.87c1.355 0 2.697.055 4.024.165C17.155 8.51 18 9.473 18 10.608v2.513m-3-4.87v-1.5m-6 1.5v-1.5m12 9.75l-1.5.75a3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0 3.354 3.354 0 00-3 0 3.354 3.354 0 01-3 0L3 16.5m15-3.38a48.474 48.474 0 00-6-.37c-2.032 0-4.034.125-6 .37m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.17c0 .62-.504 1.124-1.125 1.124H4.125A1.125 1.125 0 013 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 016 13.12M12.265 3.11a.375.375 0 11-.53 0L12 2.845l.265.265zm-3 0a.375.375 0 11-.53 0L9 2.845l.265.265zm6 0a.375.375 0 11-.53 0L15 2.845l.265.265z"
            />
          </svg>
        </div>
        <div className="flex w-full flex-row items-center justify-end">
          <span className="mr-3 text-sm text-stone-400">
            {new Date(weight.date).toLocaleString("default", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-1 h-4 w-4 text-stone-600"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}

export default WeightPreviewDesktop;
