import React from "react";
import { Link } from "react-router-dom";
import SlideMenu from "../SlideMenu";
import { useState } from "react";
function AdminTopBar(props) {
  const [isDisplay, setDisplay] = useState(false);
  const handleClose = () => {
    setDisplay(!isDisplay);
  };
  return (
    <div className="mb-3 flex h-16 items-center border-b border-stone-200 bg-amber-400 px-3 text-white">
      <SlideMenu isDisplay={isDisplay} setDisplay={handleClose}></SlideMenu>
      <Link to="/admin">
        <div className="flex cursor-pointer items-center">
          <div className="mr-3">
            <img src="/logo_header.png" className="h-12"></img>
          </div>
          <span className="font-fira text-2xl font-bold">Check'veau</span>
        </div>
      </Link>{" "}
      <div className="grow"></div>
      <button onClick={() => setDisplay(true)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="h-6 w-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>
    </div>
  );
}

export default AdminTopBar;
