import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
function NewCalf() {
  const [step, setStep] = useState(1);
  const [bdta, setBdta] = useState("");
  const [name, setName] = useState("");
  const [sex, setSex] = useState("f");
  const [birthdate, setBirthdate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [birthtime, setBirthtime] = useState("");
  const [vitality, setVitality] = useState("vif");
  const [birthtype, setBirthtype] = useState("Sans aide");
  const [colostrum, setColostrum] = useState("");
  const [colostrumMean, setColostrumMean] = useState("");
  const [colostrumSource, setColostrumSource] = useState("");
  const [colostrumContent_fer, setColostrumContent_fer] = useState(false);
  const [colostrumContent_selenium, setColostrumContent_selenium] =
    useState(false);
  const [colostrumContent_locatim, setColostrumContent_locatim] =
    useState(false);
  const [colostrumQuality, setColostrumQuality] = useState(null);
  const [apporttime, setApporttime] = useState("");
  const [vaccine, setVaccine] = useState("");
  const [vaccinated, setVaccinated] = useState(false);
  const [ecornage, setEcornage] = useState(false);
  const [ecornageDate, setEcornageDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [castration, setCastration] = useState(false);
  const [weight, setWeight] = useState("");
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const onClick = (event) => {
    event.preventDefault();
    setVaccinated(!vaccinated);
  };
  const onClickEcornage = (event) => {
    event.preventDefault();
    setEcornage(!ecornage);
  };
  const onClickCastration = (event) => {
    event.preventDefault();
    setCastration(!castration);
  };
  const handleSubmit = (event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    if (sex === "f") {
      setCastration(false);
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fk_user: currentUser.id,
        bdta: bdta,
        name: name,
        sex: sex,
        birthdate: birthdate,
        birthtime: birthtime,
        vitality: vitality,
        birthtype: birthtype,
        colostrum: colostrum,
        colostrumMean: colostrumMean,
        colostrumSource: colostrumSource,
        colostrumContent_fer: colostrumContent_fer,
        colostrumContent_selenium: colostrumContent_selenium,
        colostrumContent_locatim: colostrumContent_locatim,
        colostrumQuality: colostrumQuality,
        apporttime: apporttime,
        ecornage: ecornage,
        ecornageDate: ecornage ? ecornageDate : null,
        castration: castration,
      }),
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        let birth_weight = parseFloat(weight.replace(",", "."));
        const requestOptionsWeight = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            date: data.birthdate,
            weight: birth_weight,
            fk_calf: data.id,
          }),
        };
        fetch(process.env.REACT_APP_API_URL + "/weight/", requestOptionsWeight)
          .then(async (response) => {
            const isJson = response.headers
              .get("content-type")
              ?.includes("application/json");
            const dataWeight = isJson && (await response.json());

            // check for error response
            if (!response.ok) {
              // get error message from body or default to response status
              const error =
                (dataWeight && dataWeight.message) || response.status;
              return Promise.reject(error);
            }
            navigate("/");
          })
          .catch((error) => {
            console.error("There was an error creating a weigth!", error);
          });
        const requestOptionsVaccine = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            date: data.birthdate,
            name: vaccine,
            fk_calf: data.id,
          }),
        };
        if (vaccinated) {
          fetch(
            process.env.REACT_APP_API_URL + "/vaccine/",
            requestOptionsVaccine
          )
            .then(async (response) => {
              const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
              const dataWeight = isJson && (await response.json());

              // check for error response
              if (!response.ok) {
                // get error message from body or default to response status
                const error =
                  (dataWeight && dataWeight.message) || response.status;
                return Promise.reject(error);
              }
              navigate("/");
            })
            .catch((error) => {
              console.error("There was an error creating a weigth!", error);
            });
        }
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const handleColostrumMean = (mean) => {
    if (colostrumMean === mean) {
      setColostrumMean("");
    } else {
      setColostrumMean(mean);
    }
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    }
  }, []);
  return (
    <div className="flex flex-col">
      <TopBar link="/"></TopBar>
      <div className="mx-3 self-center rounded-lg bg-white px-3 pt-2 shadow-md md:w-[500px] md:shadow-none">
        <div className="mb-3 flex flex-col">
          <span className="text-2xl font-bold">Ajouter un veau</span>
          <p className="text-sm text-stone-400">
            Vous pouvez ajouter un nouveau veau à votre liste ici.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="flex w-full flex-col">
          <div
            className={
              "flex min-h-[600px] flex-col " + (step === 1 ? "" : "hidden")
            }
          >
            <label
              htmlFor="bdta_input"
              className="text-sm font-medium text-stone-700"
            >
              BDTA
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500 shadow-sm"
              type="text"
              value={bdta}
              onChange={(e) => setBdta(e.target.value)}
              placeholder="BDTA"
              name="bdta_input"
            ></input>

            <label
              htmlFor="name_input"
              className="text-sm font-medium text-stone-700"
            >
              Nom
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500 shadow-sm"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nom"
              name="name_input"
            ></input>

            <label
              htmlFor="sex_input"
              className="text-sm font-medium text-stone-700"
            >
              Sexe
            </label>
            <select
              className="mb-3 rounded-md border border-stone-300 bg-stone-50 p-3 shadow-sm"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              name="sex_input"
            >
              <option value="f">Femelle</option>
              <option value="m">Mâle</option>
            </select>
            <div
              className={"my-3 flex flex-row " + (sex === "f" ? "hidden" : "")}
            >
              <span className="mr-10 text-sm font-medium text-stone-700">
                Castré
              </span>
              <button
                className={
                  "flex  h-8 w-14 items-center rounded-full p-1 ring-1 ring-inset transition-colors duration-200 ease-in-out " +
                  (castration
                    ? "bg-amber-500 ring-black/20"
                    : "bg-stone-300 ring-slate-900/5")
                }
                onClick={(e) => onClickCastration(e)}
                type="button"
              >
                <span
                  className={
                    "inline-block h-6 w-6 transform rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out " +
                    (castration ? "translate-x-6" : "")
                  }
                ></span>
              </button>
            </div>
            <div className="grow"></div>
            <div className="mx-auto my-6 flex w-16 justify-between">
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-stone-200"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-stone-200"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-stone-200"></div>
            </div>
            <div className="flex">
              <div className="w-1/2 pr-2">
                <Link to="/">
                  <button
                    className="w-full rounded-lg border border-stone-300 bg-white py-3 font-medium text-stone-600"
                    type="button"
                  >
                    Annuler
                  </button>
                </Link>
              </div>
              <div className="w-1/2 pl-2">
                <button
                  className="mb-3 w-full rounded-lg border border-amber-400 bg-amber-400 px-3 py-3 font-medium text-white"
                  type="button"
                  onClick={() => setStep(2)}
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              "flex min-h-[600px] flex-col " + (step === 2 ? "" : "hidden")
            }
          >
            <label
              htmlFor="birthday_input"
              className="text-sm font-medium text-stone-700"
            >
              Date de naissance
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500 shadow-sm"
              type="date"
              defaultValue={birthdate}
              onChange={(e) => setBirthdate(e.target.value)}
              placeholder="Date de naissance"
              name="birthday_input"
            ></input>

            <label
              htmlFor="birthtime_input"
              className="text-sm font-medium text-stone-700"
            >
              Heure de naissance
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500 shadow-sm"
              type="time"
              value={birthtime}
              onChange={(e) => setBirthtime(e.target.value)}
              placeholder="Heure de naissance"
              name="birthtime_input"
            ></input>
            <label
              htmlFor="vitality_input"
              className="text-sm font-medium text-stone-700"
            >
              Vitalité
            </label>
            <select
              className="mb-3 rounded-md border border-stone-300 bg-stone-50 p-3 shadow-sm"
              value={vitality}
              onChange={(e) => setVitality(e.target.value)}
              name="vitality_input"
            >
              <option value="vif">Vif</option>
              <option value="apathique">Apathique</option>
              <option value="mort">Mort</option>
            </select>
            <label
              htmlFor="weight_input"
              className="text-sm font-medium text-stone-700"
            >
              Poids à la naissance
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500 shadow-sm"
              type="text"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              placeholder="poids en Kg"
              name="weight_input"
            ></input>
            <label
              htmlFor="birthtype_input"
              className="text-sm font-medium text-stone-700"
            >
              Déroulement de la naissance
            </label>
            <select
              className="mb-3 rounded-md border border-stone-300 bg-stone-50 p-3 shadow-sm"
              value={birthtype}
              onChange={(e) => setBirthtype(e.target.value)}
              name="birthtype_input"
            >
              <option value="sans aide">Sans aide</option>
              <option value="aide faible">Aide faible</option>
              <option value="naissance difficile">Naissance difficile</option>
            </select>
            <div className="grow"></div>
            <div className="mx-auto my-6 flex w-16 justify-between">
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-stone-200"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-stone-200"></div>
            </div>
            <div className="flex">
              <div className="w-1/2 pr-2">
                <button
                  className="w-full rounded-lg border border-stone-300 bg-white py-3 font-medium text-stone-600"
                  type="button"
                  onClick={() => setStep(1)}
                >
                  Retour
                </button>
              </div>
              <div className="w-1/2 pl-2">
                <button
                  className="mb-3 w-full rounded-lg border border-amber-400 bg-amber-400 px-3 py-3 font-medium text-white"
                  type="button"
                  onClick={() => setStep(3)}
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
          <div
            className={
              "flex min-h-[600px] flex-col " + (step === 3 ? "" : "hidden")
            }
          >
            <label
              htmlFor="colostrum_input"
              className="text-sm font-medium text-stone-700"
            >
              1er apport en colostrum
            </label>
            <div className="mb-3 flex items-center justify-between rounded-lg border border-stone-300 bg-stone-100 shadow-sm">
              <input
                className="grow bg-transparent p-3 text-left text-stone-500"
                type="text"
                value={colostrum}
                onChange={(e) => setColostrum(e.target.value)}
                placeholder="Apport en colostrum"
                name="colostrum_input"
              ></input>
              <div className=" mr-3 text-stone-500">
                <span className="font-fira italic">l</span>
              </div>
            </div>
            <label
              htmlFor="apporttime_input"
              className="text-sm font-medium text-stone-700"
            >
              Heure de l'apport
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500 shadow-sm"
              type="time"
              value={apporttime}
              onChange={(e) => setApporttime(e.target.value)}
              placeholder="Heure de l'apport"
              name="apporttime_input"
            ></input>
            <label
              htmlFor="colostrumMean_input"
              className="mt-3 text-sm font-medium text-stone-700"
            >
              Apport colostrumMean
            </label>
            <select
              className="mb-3 rounded-md border border-stone-300 bg-stone-50 p-3 shadow-sm"
              value={colostrumQuality}
              onChange={(e) => setColostrumMean(e.target.value)}
              name="colostrumMean_input"
            >
              <option value="Biberon">biberon</option>
              <option value="Sonde">Sonde</option>
            </select>
            <label
              htmlFor="colostrumSource_input"
              className="mt-3 text-sm font-medium text-stone-700"
            >
              Apport
            </label>
            <select
              className="mb-3 rounded-md border border-stone-300 bg-stone-50 p-3 shadow-sm"
              value={colostrumSource}
              onChange={(e) => setColostrumSource(e.target.value)}
              name="colostrumSource_input"
            >
              <option value="Maternel">Maternel</option>
              <option value="Congelé">Congelé</option>
              <option value="Autre">Autre</option>
            </select>
            <label
              htmlFor="colostrumQuality_input"
              className="mt-3 text-sm font-medium text-stone-700"
            >
              Apport colostrumquality
            </label>
            <select
              className="mb-3 rounded-md border border-stone-300 bg-stone-50 p-3 shadow-sm"
              value={colostrumQuality}
              onChange={(e) => setColostrumQuality(e.target.value)}
              name="colostrumQuality_input"
            >
              <option value="Bon">Bon</option>
              <option value="Moyen">Moyen</option>
              <option value="Mauvais">Mauvais</option>
            </select>
            <div className="my-3 flex flex-row">
              <span className="mr-10 text-sm font-medium text-stone-700">
                Fer
              </span>
              <button
                className={
                  "flex  h-8 w-14 items-center rounded-full p-1 ring-1 ring-inset transition-colors duration-200 ease-in-out " +
                  (colostrumContent_fer
                    ? "bg-amber-500 ring-black/20"
                    : "bg-stone-300 ring-slate-900/5")
                }
                onClick={(e) =>
                  setColostrumContent_fer(
                    (prevcolostrumContent_fer) => !prevcolostrumContent_fer
                  )
                }
              >
                <span
                  className={
                    "inline-block h-6 w-6 transform rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out " +
                    (colostrumContent_fer ? "translate-x-6" : "")
                  }
                ></span>
              </button>
            </div>
            <div className="my-3 flex flex-row">
              <span className="mr-10 text-sm font-medium text-stone-700">
                Sélénium
              </span>
              <button
                className={
                  "flex  h-8 w-14 items-center rounded-full p-1 ring-1 ring-inset transition-colors duration-200 ease-in-out " +
                  (colostrumContent_selenium
                    ? "bg-amber-500 ring-black/20"
                    : "bg-stone-300 ring-slate-900/5")
                }
                onClick={(e) =>
                  setColostrumContent_selenium(
                    (prevcolostrumContent_selenium) =>
                      !prevcolostrumContent_selenium
                  )
                }
              >
                <span
                  className={
                    "inline-block h-6 w-6 transform rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out " +
                    (colostrumContent_selenium ? "translate-x-6" : "")
                  }
                ></span>
              </button>
            </div>
            <div className="my-3 flex flex-row">
              <span className="mr-10 text-sm font-medium text-stone-700">
                Locatim
              </span>
              <button
                className={
                  "flex  h-8 w-14 items-center rounded-full p-1 ring-1 ring-inset transition-colors duration-200 ease-in-out " +
                  (colostrumContent_locatim
                    ? "bg-amber-500 ring-black/20"
                    : "bg-stone-300 ring-slate-900/5")
                }
                onClick={(e) =>
                  setColostrumContent_locatim(
                    (prevcolostrumContent_locatim) =>
                      !prevcolostrumContent_locatim
                  )
                }
              >
                <span
                  className={
                    "inline-block h-6 w-6 transform rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out " +
                    (colostrumContent_locatim ? "translate-x-6" : "")
                  }
                ></span>
              </button>
            </div>
            <div className="mx-auto my-6 flex w-16 justify-between">
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-stone-200"></div>
            </div>
            <div className="flex">
              <div className="w-1/2 pr-2">
                <button
                  className="w-full rounded-lg border border-stone-300 bg-white py-3 font-medium text-stone-600"
                  type="button"
                  onClick={() => setStep(2)}
                >
                  Retour
                </button>
              </div>
              <div className="w-1/2 pl-2">
                <button
                  className="mb-3 w-full rounded-lg border border-amber-400 bg-amber-400 px-3 py-3 font-medium text-white"
                  type="button"
                  onClick={() => setStep(4)}
                >
                  Suivant
                </button>
              </div>
            </div>
          </div>
          <div className={step === 4 ? "" : "hidden"}>
            <div className="my-3 flex flex-row">
              <span className="mr-10 text-sm font-medium text-stone-700">
                Vacciné
              </span>
              <button
                className={
                  "flex  h-8 w-14 items-center rounded-full p-1 ring-1 ring-inset transition-colors duration-200 ease-in-out " +
                  (vaccinated
                    ? "bg-amber-500 ring-black/20"
                    : "bg-stone-300 ring-slate-900/5")
                }
                onClick={(e) => onClick(e)}
              >
                <span
                  className={
                    "inline-block h-6 w-6 transform rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out " +
                    (vaccinated ? "translate-x-6" : "")
                  }
                ></span>
              </button>
            </div>
            <div
              className={"my-3 flex flex-col " + (vaccinated ? "" : "hidden")}
            >
              <label
                htmlFor="vaccine_input"
                className="text-sm font-medium text-stone-700"
              >
                Vaccin
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
                type="text"
                value={vaccine}
                onChange={(e) => setVaccine(e.target.value)}
                name="vaccine_input"
              ></input>
            </div>
            <div className="my-6 flex flex-row">
              <span className="mr-10 text-sm font-medium text-stone-700">
                Ecornage
              </span>
              <button
                className={
                  "flex  h-8 w-14 items-center rounded-full p-1 ring-1 ring-inset transition-colors duration-200 ease-in-out " +
                  (ecornage
                    ? "bg-amber-500 ring-black/20"
                    : "bg-stone-300 ring-slate-900/5")
                }
                onClick={(e) => onClickEcornage(e)}
              >
                <span
                  className={
                    "inline-block h-6 w-6 transform rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out " +
                    (ecornage ? "translate-x-6" : "")
                  }
                ></span>
              </button>
            </div>
            <div className={"my-3 flex flex-col " + (ecornage ? "" : "hidden")}>
              <label
                className="mb-3 text-sm font-medium text-stone-700"
                htmlFor="ecornageDate_input"
              >
                Date d'écornage
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
                type="date"
                value={ecornageDate}
                onChange={(e) => setEcornageDate(e.target.value)}
                placeholder="Date d'ecornage"
                name="ecornageDate_input"
              ></input>
            </div>
            <div className="grow"></div>
            <div className="mx-auto my-6 flex w-16 justify-between">
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
              <div className="h-[8px] w-[8px] rounded-full bg-amber-400"></div>
            </div>
            <div className="flex">
              <div className="mb-3 w-1/2 pr-2">
                <button
                  className="w-full rounded-lg border border-stone-300 bg-white py-3 font-medium text-stone-600"
                  type="button"
                  onClick={() => setStep(3)}
                >
                  Retour
                </button>
              </div>
              <div className="w-1/2 pl-2">
                <button
                  type="submit"
                  className="w-full rounded-lg border border-amber-500 bg-amber-400 p-3 font-medium text-white"
                >
                  Ajouter
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NewCalf;
