import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../../services/auth.service";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import TopBar from "../../components/TopBar";
import RolesPastilles from "../../components/RolesPastilles";
import AdminTopBar from "../../components/Admin/AdminTopBar";
import { Dialog, Transition } from "@headlessui/react";
function UpdateUser() {
  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]);
  const [isUser, setisUser] = useState(false);
  const [isAdmin, setisAdmin] = useState(false);
  const [password, setPassword] = useState(suggestPassword());
  const [isOpen, setIsOpen] = useState(false);
  const [vue, setVue] = useState("info");
  const currentUser = AuthService.getCurrentUser();
  let { id } = useParams();
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  const handleCheckboxUser = (event) => {
    setisUser(event.target.checked);
  };
  const handleCheckboxAdmin = (event) => {
    setisAdmin(event.target.checked);
  };
  const getUser = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/user/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setUser(data);
      })
      .catch((error) => {
        console.error("There was an error getting the users!", error);
      });
  };

  const getRoles = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/roles/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setRoles(data);
        data.forEach((element) => {
          if (element.name === "user") {
            setisUser(true);
          }
          if (element.name === "admin") {
            setisAdmin(true);
          }
        });
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const updateUser = () => {
    let rolesArray = [];
    if (isUser) rolesArray.push("user");
    if (isAdmin) rolesArray.push("admin");
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
      body: JSON.stringify({
        id: id,
        nom: user.nom,
        username: user.username,
        email: user.email,
        roles: rolesArray,
      }),
    };

    fetch(process.env.REACT_APP_API_URL + "/auth/update", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error getting the users!", error);
      });
  };
  const updateUserPassword = () => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
      body: JSON.stringify({
        id: id,
        password: password,
      }),
    };

    fetch(
      process.env.REACT_APP_API_URL + "/auth/updatePassword",
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error getting the users!", error);
      });
  };
  function suggestPassword() {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (let j = 0; j < 3; j++) {
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      if (j < 2) result += "-";
    }

    return result;
  }
  useEffect(() => {
    //Runs only on the first render
    getUser();
    getRoles();
  }, []);
  return (
    <div>
      <AdminTopBar></AdminTopBar>
      <div className="mx-auto flex w-[1200px] flex-col rounded-lg bg-white px-6 pt-3 pb-6">
        <div className="my-3 flex w-fit items-center justify-center rounded-lg bg-stone-200/60 px-3 py-[2px]">
          <Link to="/admin">
            <span className="text-sm text-stone-700">
              Liste des utilisateurs
            </span>
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-1 h-4 w-4 text-stone-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
          <Link to={"/admin/user/" + user.id}>
            <span className="text-sm  text-stone-700">{user.username}</span>
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-1 h-4 w-4 text-stone-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
          <span className="text-sm font-semibold text-stone-700">
            Paramètres
          </span>
        </div>

        <span className="text-2xl font-bold">Paramètres</span>
        <span className="text-sm text-stone-400">
          Vous pouvez modifier ici les informations du compte {user.username}.
        </span>
        <div>
          <div className="my-6 flex border-b border-stone-200 text-sm">
            <button onClick={() => setVue("info")}>
              <div
                className={
                  "flex items-center justify-center border-b-2 border-amber-500 py-1 px-3 " +
                  (vue == "info"
                    ? " border-amber-500 text-amber-500"
                    : "border-white text-stone-400")
                }
              >
                <span className="font-semibold">Informations</span>
              </div>
            </button>
            <button onClick={() => setVue("password")}>
              <div
                className={
                  "flex items-center justify-center border-b-2 border-amber-500 py-1 px-3 " +
                  (vue == "password"
                    ? " border-amber-500 text-amber-500"
                    : "border-white text-stone-400")
                }
              >
                <span className="font-semibold ">Mot de passe</span>
              </div>
            </button>
          </div>
        </div>
        <div className={vue == "info" ? "" : "hidden"}>
          <div className=" mt-6">
            <span className="font-semibold">Informations de connexion</span>
          </div>
          <div className="my-3 w-full border-b border-stone-200"></div>
          <div className="mb-10 flex">
            <div className="mr-16 w-1/3">
              <span className="text-sm font-medium">Nom</span>
              <p className="text-xs text-stone-400"></p>
            </div>
            <div className="w-2/3 grow">
              <input
                className="w-full rounded-md border border-stone-300 py-2 px-3 text-sm text-stone-600 shadow"
                value={user.nom ? user.nom : ""}
                onChange={(e) =>
                  setUser({
                    ...user,
                    nom: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>
          <div className="mb-10 flex">
            <div className="mr-16 w-1/3">
              <span className="text-sm font-medium">Nom d'utilisateur</span>
              <p className="text-xs text-stone-400">
                Le nom d'utilisateur est utilisé pour se connecté à
                l'application
              </p>
            </div>
            <div className="w-2/3 grow">
              <input
                className="w-full rounded-md border border-stone-300 py-2 px-3 text-sm text-stone-600 shadow"
                value={user.username ? user.username : ""}
                onChange={(e) =>
                  setUser({
                    ...user,
                    username: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>
          <div className="mb-10 flex">
            <div className="mr-16 w-1/3">
              <span className="text-sm font-medium">Adresse éléctronique</span>
              <p className="text-xs text-stone-400">
                L'adresse E-Mail d'un utilisateur n'est pour l'instant pas
                tellement utilisé...
              </p>
            </div>
            <div className="w-2/3 grow">
              <input
                className="w-full rounded-md border border-stone-300 py-2 px-3 text-sm text-stone-600 shadow"
                value={user.email ? user.email : ""}
                onChange={(e) =>
                  setUser({
                    ...user,
                    email: e.target.value,
                  })
                }
              ></input>
            </div>
          </div>

          <div className=" mt-12">
            <span className="font-semibold">Rôles</span>
          </div>
          <div className="my-3 w-full border-b border-stone-200"></div>
          <div className="mb-2 flex items-center">
            <input
              type="checkbox"
              id="user_role"
              name="user_role"
              className="h-4 w-4 appearance-none rounded-[4px] accent-amber-500"
              checked={isUser}
              onChange={handleCheckboxUser}
            ></input>
            <label htmlFor="user_role" className="ml-2 text-sm font-medium">
              Utilisateur
            </label>
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              id="admin_role"
              name="admin_role"
              className="h-4 w-4 appearance-none rounded-[4px] accent-amber-500"
              checked={isAdmin}
              onChange={handleCheckboxAdmin}
            ></input>
            <label htmlFor="admin_role" className="ml-2 text-sm font-medium">
              Administrateur
            </label>
          </div>
          <div className="mb-3 mt-12 flex justify-end">
            <button
              className="mr-3 rounded-md border border-stone-200 py-2 px-3 text-sm text-stone-800 shadow transition-shadow duration-200 hover:shadow-md"
              onClick={() => setIsOpen(true)}
            >
              Supprimer
            </button>
            <button
              className="rounded-md border border-amber-500 bg-amber-400 py-2 px-3 text-sm font-medium text-white shadow transition-colors duration-200 hover:shadow-inner"
              onClick={() => updateUser()}
            >
              Enregistrer
            </button>
          </div>
        </div>
        <div className={vue == "password" ? "" : "hidden"}>
          <div className=" mt-6">
            <span className="font-semibold">Mot de passe</span>
          </div>
          <div className="my-3 w-full border-b border-stone-200"></div>
          <div className="mt-3 flex">
            <div className="mr-16 w-1/3">
              <span className="text-sm font-medium">
                Reinitialiser le mot de passe
              </span>
              <p className="text-xs text-stone-400">
                Vous pouvez changer le mot de passe d'un utilisateur qui
                l'aurait oublié.
              </p>
            </div>
            <div className="flex h-fit w-2/3 grow">
              <input
                className="w-full rounded-l-md border border-stone-300 py-2 px-3 text-sm text-stone-600 shadow"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
              <button
                className="mr-3 rounded-r-md border-y border-r border-stone-300 bg-white py-2 px-3 text-sm font-medium text-stone-600 shadow hover:bg-stone-50"
                onClick={(e) => setPassword(suggestPassword())}
              >
                Suggestion
              </button>
              <button
                className="rounded-md border border-amber-500 bg-amber-400 py-2 px-3 text-sm font-medium text-white shadow hover:shadow-inner"
                onClick={() => updateUserPassword()}
              >
                Réinitialiser
              </button>
            </div>
          </div>
        </div>

        <Transition appear show={isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg font-medium leading-6 text-gray-900"
                    >
                      Suppression du compte
                    </Dialog.Title>
                    <div className="mt-6">
                      <p className="text-center text-sm text-gray-500">
                        Etes-vous sûr de vouloir supprimer le compte{" "}
                        {user.username} définitivement ? Toutes les données
                        associé à ce compte seront effacicé, y compris tous les
                        veaux.
                      </p>
                    </div>

                    <div className="mt-6 flex justify-around">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-stone-100 px-4 py-2 text-sm font-medium text-stone-900 transition-colors duration-200 hover:bg-stone-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-amber-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Annuler
                      </button>
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white transition-colors duration-200 hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Comfirmer
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}

export default UpdateUser;
