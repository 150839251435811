import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../../services/auth.service";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import AdminTopBar from "../../components/Admin/AdminTopBar";
import RolesPastilles from "../../components/RolesPastilles";
function Admin() {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = AuthService.getCurrentUser();
  if (!currentUser.roles.includes("ROLE_ADMIN")) navigate("/");
  const setIsActif = (userID) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
      body: JSON.stringify({ isActif: false }),
    };

    fetch(
      process.env.REACT_APP_API_URL + "/user/setIsActif/" + userID,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        getUsers();
      })
      .catch((error) => {
        console.error("There was an error getting the users!", error);
      });
  };
  const getUsers = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/users/", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setUsers(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error getting the users!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    getUsers();
  }, []);
  return (
    <div>
      <AdminTopBar></AdminTopBar>
      <div className="mx-auto w-[1000px]">
        <div className="mt-10 flex justify-between">
          <div className="flex flex-col">
            <span className="text-2xl font-semibold">
              Liste des utilisateurs
            </span>
            <p className="text-sm text-stone-500">
              Gérez la liste des comptes qui ont accès à l'application
            </p>
          </div>
          <div className="flex">
            <Link to="/nouvel_utilisateur">
              <button className="mr-3 flex items-center rounded-md border border-amber-500 bg-amber-400 py-2 px-3 text-sm font-medium text-white shadow transition-colors duration-100 hover:shadow-inner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="mr-1 h-5 w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 4.5v15m7.5-7.5h-15"
                  />
                </svg>
                <span>Nouveau</span>
              </button>
            </Link>
          </div>
        </div>
        <div>
          <div className="my-6 flex border-b border-stone-200 text-sm">
            <div className="flex items-center justify-center border-b-2 border-amber-500 py-1 px-3">
              <span className="font-semibold text-amber-500">Actif</span>
            </div>
            <div className="py-1 px-3">
              <span className="font-semibold text-stone-400">Tous</span>
            </div>
            <div className="py-1 px-3">
              <span className="font-semibold text-stone-400">Désactivés</span>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto rounded-lg border border-stone-200 bg-white pb-3">
          <div className="flex w-full items-center px-3 py-3">
            <span className="text-lg font-bold">Utilisateurs</span>
            <span className="ml-3 rounded-md border border-stone-300 px-2 py-[2px] text-xs font-medium text-stone-700">
              {users.length} comptes
            </span>
            <div className="grow"></div>

            <input
              placeholder="Recherche"
              className=" rounded-md border border-stone-200 px-3 py-2 text-sm"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>
          <div className="table w-full border-collapse whitespace-nowrap">
            <div className="table-row border-y border-stone-200 bg-stone-100 text-xs text-stone-700">
              <div className="table-cell py-3 pl-3">
                <div className="h-3 w-3 border border-stone-300"></div>
              </div>
              <div className="table-cell">Nom</div>
              <div className="table-cell">Status</div>
              <div className="table-cell">E-Mail</div>
              <div className="table-cell">Roles</div>
              <div className="table-cell">Créé le</div>
              <div className="table-cell">actions</div>
            </div>
            <div className="table-row-group text-xs">
              {users
                .filter(function (item) {
                  return (
                    item.username.toLowerCase().includes(search) ||
                    item.email.toLowerCase().includes(search)
                  );
                })
                .map((item, index) => (
                  <div
                    key={item.id}
                    className={
                      "table-row border-y border-stone-200 transition-colors duration-150 hover:bg-stone-50 "
                    }
                  >
                    <div className="table-cell py-3 pl-3 align-middle">
                      <input
                        type="checkbox"
                        className="appearance-none rounded-[4px]"
                      ></input>
                    </div>
                    <div className="table-cell py-3">
                      <Link to={"/admin/user/" + item.id}>
                        <div className="cursor-pointer">
                          <span className="font-semibold hover:underline">
                            {item.nom}
                          </span>
                        </div>
                      </Link>
                      <div>
                        {" "}
                        <span className="text-stone-500">@{item.username}</span>
                      </div>
                    </div>
                    <div className="table-cell align-middle text-xs text-stone-400">
                      <div
                        className={
                          "w-fit items-center rounded-md border border-slate-300 py-[2px] px-2 font-medium  text-stone-800 " +
                          (item.isActif ? "flex" : "hidden")
                        }
                      >
                        <div className="mr-2 h-2 w-2 rounded-full bg-green-500"></div>
                        <span>Actif</span>
                      </div>
                      <div
                        className={
                          "w-fit items-center rounded-md border border-slate-300 py-[2px] px-2 font-medium  text-stone-800 " +
                          (item.isActif ? "hidden" : "flex")
                        }
                      >
                        <div className="mr-2 h-2 w-2 rounded-full bg-red-500"></div>
                        <span>Inactif</span>
                      </div>
                    </div>
                    <div className="table-cell align-middle text-xs text-stone-400">
                      {item.email}
                    </div>
                    <div className="table-cell align-middle">
                      <RolesPastilles id={item.id}></RolesPastilles>
                    </div>
                    <div className="table-cell align-middle text-xs text-stone-400">
                      {new Date(item.createdAt).toLocaleString("fr-ch", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </div>
                    <div className="table-cell align-middle">
                      <button
                        onClick={() => setIsActif(item.id)}
                        className="mr-5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="h-4 w-4 text-stone-700"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </button>
                      <Link to={"/admin/user/" + item.id}>
                        <button>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="h-4 w-4 text-stone-700"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                            />
                          </svg>
                        </button>
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Admin;
