import React from "react";
import { useState } from "react";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [displayWrongPassword, setDisplayWrongPassword] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    AuthService.login(username, password).then(
      () => {
        console.log("logged !");
        navigate("/");
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        console.log("error");
        setDisplayWrongPassword(true);
      }
    );
  };
  return (
    <div className="flex h-screen bg-white md:flex md:flex-col md:items-center ">
      <div className="flex w-full justify-center md:w-[400px] md:translate-y-32">
        <div className="flex w-full flex-col items-center">
          <div className="my-6 mx-3 flex flex-col items-center">
            <img src="/logo.png" className="mb-6 w-16"></img>
            <span className="text-lg font-semibold">
              Connectez-vous à votre compte
            </span>
            <span className="text-center text-sm text-slate-600">
              Veuillez entrer vos identifiants afin de vous connecter à votre
              compte.
            </span>
          </div>
          <form
            onSubmit={handleSubmit}
            className="my-3 flex w-full flex-col px-3"
          >
            <div className="mb-5 flex w-full flex-col">
              <label htmlFor="email_input" className="font-medium">
                Nom d'utilisateur
              </label>
              <input
                className="mt-2 w-full rounded-lg border border-stone-300 bg-stone-100 px-3 py-3 text-stone-500"
                type="text"
                name="email_input"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              ></input>
            </div>
            <div className="flex w-full flex-col">
              <label htmlFor="password_input" className="font-medium">
                Mot de passe
              </label>
              <input
                className={
                  "mt-2 mb-12 w-full rounded-lg border border-stone-300 px-3 py-3 text-stone-400 " +
                  (displayWrongPassword
                    ? "shadow shadow-red-300 ring-1 ring-red-400"
                    : "")
                }
                type="password"
                name="password_input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              ></input>
            </div>
            <div className={displayWrongPassword ? "" : "hidden"}>
              <span className="text-sm text-red-500">
                Mot de passe incorrect !
              </span>
            </div>
            <span className="my-6 hidden cursor-pointer self-end font-semibold text-amber-400">
              Mot de passe oublié
            </span>
            <button
              className="w-full cursor-pointer rounded-lg border border-amber-500 bg-amber-400 p-3 font-semibold text-white shadow transition-colors duration-200 hover:shadow-inner"
              type="submit"
            >
              Se connecter
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
