import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { formatDate } from "../utils/dateUtils";
function UpdateDataDesktop(props) {
  const [pk_calf, setPK_calf] = useState(0);
  const [bdta, setBdta] = useState("");
  const [name, setName] = useState("");
  const [sex, setSex] = useState("f");
  const [birthdate, setBirthdate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [birthtime, setBirthtime] = useState("");
  const [vitality, setVitality] = useState("vif");
  const [birthtype, setBirthtype] = useState("Sans aide");
  const [colostrum, setColostrum] = useState("");
  const [apporttime, setApporttime] = useState("");
  const [ecornage, setEcornage] = useState(false);
  const [ecornageDate, setEcornageDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [castration, setCastration] = useState(false);
  const [weight, setWeight] = useState("");
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [isLoading, setLoading] = useState(true);
  let { id } = useParams();
  const onClick = (event) => {
    event.preventDefault();
    setEcornage(!ecornage);
  };
  const onClickCastration = (event) => {
    event.preventDefault();
    setCastration(!castration);
  };

  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/calfs/" + props.calfID,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setPK_calf(data.pk_calf);
        setBdta(data.bdta);
        setName(data.name);
        setSex(data.sex);
        setBirthdate(formatDate(new Date(data.birthdate)));
        setBirthtime(data.birthtime);
        setVitality(data.vitality);
        setBirthtype(data.birthtype);
        setColostrum(data.colostrum);
        setApporttime(data.apporttime);
        setWeight(data.weight);
        setEcornage(data.ecornage);
        setEcornageDate(formatDate(new Date(data.ecornageDate)));
        setCastration(data.castration);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const handleSubmit = (event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        pk_calf: pk_calf,
        fk_user: currentUser.id,
        bdta: bdta,
        name: name,
        sex: sex,
        birthdate: formatDate(new Date(birthdate)),
        birthtime: birthtime,
        vitality: vitality,
        birthtype: birthtype,
        colostrum: colostrum,
        apporttime: apporttime,
        ecornage: ecornage,
        ecornageDate: ecornage ? ecornageDate : null,
        castration: sex === "f" ? false : castration,
      }),
    };
    fetch(process.env.REACT_APP_API_URL + "/calfs/" + pk_calf, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        props.getCalf();
        props.closeModal();
      })
      .catch((error) => {
        console.error("There was an error updating a calf!", error);
      });
  };
  const handleCastration = (event) => {
    setCastration(event.target.checked);
  };
  const handleEcornage = (event) => {
    setEcornage(event.target.checked);
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getCalf();
    }
  }, []);
  return (
    <div>
      <div className="pt-2">
        <form onSubmit={handleSubmit} className="flex w-full flex-col">
          <div className="flex flex-row border-t border-stone-100 pt-3 pb-2">
            <div className="w-1/3">
              <span className="text-sm font-medium text-stone-700">
                Informations de base
              </span>
            </div>
            <div className="w-2/3 ">
              <label
                htmlFor="bdta_input"
                className="text-sm font-medium text-stone-700"
              >
                BDTA
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-sm text-stone-500 shadow-sm"
                type="text"
                value={bdta}
                onChange={(e) => setBdta(e.target.value)}
                placeholder="BDTA"
                name="bdta_input"
              ></input>

              <label
                htmlFor="name_input"
                className="text-sm font-medium text-stone-700"
              >
                Nom
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-sm text-stone-500 shadow-sm"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nom"
                name="name_input"
              ></input>

              <label
                htmlFor="sex_input"
                className="text-sm font-medium text-stone-700"
              >
                Sexe
              </label>
              <select
                className="mb-3 block rounded-md border border-stone-300 px-3 py-2 pr-12 shadow-sm"
                value={sex}
                onChange={(e) => setSex(e.target.value)}
                name="sex_input"
              >
                <option value="f">Femelle</option>
                <option value="m">Mâle</option>
              </select>
              <div
                className={
                  "my-3 flex flex-row " + (sex === "f" ? "hidden" : "")
                }
              >
                <div className="flex flex-row items-center">
                  <input
                    className="form-checkbox mr-2 cursor-pointer rounded-sm"
                    type="checkbox"
                    id="castreCheckbox"
                    name="castreCheckbox"
                    checked={castration}
                    onChange={handleCastration}
                  />
                  <label htmlFor="castreCheckbox" className="cursor-pointer">
                    Castré
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row border-t border-stone-100 pt-3 pb-2">
            <div className="w-1/3">
              <span className="text-sm font-medium text-stone-700">
                Naissance
              </span>
            </div>
            <div className="w-2/3 ">
              <label
                htmlFor="birthday_input"
                className="text-sm font-medium text-stone-700"
              >
                Date de naissance
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-sm text-stone-500 shadow-sm"
                type="date"
                value={birthdate}
                onChange={(e) => setBirthdate(e.target.value)}
                placeholder="Date de naissance"
                name="birthday_input"
              ></input>

              <label
                htmlFor="birthtime_input"
                className="text-sm font-medium text-stone-700"
              >
                Heure de naissance
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-sm text-stone-500 shadow-sm"
                type="time"
                value={birthtime}
                onChange={(e) => setBirthtime(e.target.value)}
                placeholder="Heure de naissance"
                name="birthtime_input"
              ></input>
              <label
                htmlFor="vitality_input"
                className="text-sm font-medium text-stone-700"
              >
                Vitalité
              </label>
              <select
                className="mb-3 block rounded-md border border-stone-300 px-3 py-2 pr-12 shadow-sm"
                value={vitality}
                onChange={(e) => setVitality(e.target.value)}
                name="vitality_input"
              >
                <option value="vif">Vif</option>
                <option value="apathique">Apathique</option>
                <option value="mort">Mort</option>
              </select>

              <label
                htmlFor="birthtype_input"
                className="text-sm font-medium text-stone-700"
              >
                Déroulement de la naissance
              </label>
              <select
                className="mb-3 block rounded-md border border-stone-300 px-3 py-2 pr-12 shadow-sm"
                value={birthtype}
                onChange={(e) => setBirthtype(e.target.value)}
                name="birthtype_input"
              >
                <option value="sans aide">Sans aide</option>
                <option value="aide faible">Aide faible</option>
                <option value="naissance difficile">Naissance difficile</option>
              </select>
            </div>
          </div>
          <div className="flex flex-row border-t border-stone-100 pt-3 pb-2">
            <div className="w-1/3">
              <span className="text-sm font-medium text-stone-700">Apport</span>
            </div>
            <div className="w-2/3 ">
              <label
                htmlFor="colostrum_input"
                className="text-sm font-medium text-stone-700"
              >
                1er apport en colostrum
              </label>
              <div className="mb-3 flex items-center justify-between rounded-lg border border-stone-300 text-sm shadow-sm">
                <input
                  className="grow border-none bg-transparent px-3 py-2 text-left text-stone-500"
                  type="text"
                  value={colostrum}
                  onChange={(e) => setColostrum(e.target.value)}
                  placeholder="Apport en colostrum"
                  name="colostrum_input"
                ></input>
                <div className=" mr-3 text-stone-500">
                  <span className="font-fira italic">l</span>
                </div>
              </div>
              <label
                htmlFor="apporttime_input"
                className="text-sm font-medium text-stone-700"
              >
                Heure de l'apport
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-sm text-stone-500 shadow-sm"
                type="time"
                value={apporttime}
                onChange={(e) => setApporttime(e.target.value)}
                placeholder="Heure de l'apport"
                name="apporttime_input"
              ></input>
            </div>
          </div>

          <div className="flex flex-row border-t border-stone-100 pt-3 pb-2">
            <div className="w-1/3">
              <span className="text-sm font-medium text-stone-700">
                Écornage
              </span>
            </div>
            <div className="w-2/3 ">
              <div className="my-3 flex flex-row">
                <div className="flex flex-row items-center">
                  <input
                    className="form-checkbox mr-2 cursor-pointer rounded-sm"
                    type="checkbox"
                    id="ecornageCheckbox"
                    name="ecornageCheckbox"
                    checked={ecornage}
                    onChange={handleEcornage}
                  />
                  <label htmlFor="ecornageCheckbox" className="cursor-pointer">
                    Écornage
                  </label>
                </div>
              </div>
              <div className={ecornage ? "" : "hidden"}>
                <label
                  htmlFor="ecornageDate_input"
                  className="text-sm font-medium text-stone-700"
                >
                  Date d'écornage
                </label>
                <input
                  className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-sm text-stone-500 shadow-sm"
                  type="date"
                  value={ecornageDate}
                  onChange={(e) => setEcornageDate(e.target.value)}
                  placeholder="Date d'ecornage'"
                  name="ecornageDate_input"
                ></input>
              </div>
            </div>
          </div>

          <div className="flex border-t border-stone-200 pt-6">
            <div className="w-1/2 pr-2">
              {" "}
              <button
                onClick={() => props.closeModal()}
                className="w-full rounded-md border border-stone-200  px-3 py-2 text-sm text-stone-700 shadow transition-all duration-100 hover:shadow-inner"
              >
                Annuler
              </button>
            </div>
            <div className="w-1/2 pl-2">
              <button
                type="submit"
                className="w-full rounded-md border border-amber-500 bg-amber-400 px-3 py-2 text-sm text-white shadow transition-all duration-100 hover:shadow-inner"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default UpdateDataDesktop;
