import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
function RolesPastilles(props) {
  const [roles, setRoles] = useState([]);
  const currentUser = AuthService.getCurrentUser();
  const getRoles = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/roles/" + props.id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        console.log(data);
        setRoles(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  useEffect(() => {
    getRoles();
  }, []);
  return (
    <div className="flex">
      {roles.map((item, index) => (
        <div
          key={item.name + index}
          className={
            "mr-1 rounded-full border px-2 py-[2px] font-medium " +
            (item.name === "user"
              ? "border-blue-600 bg-blue-50 text-blue-600"
              : "border-orange-500 bg-orange-50 text-orange-500")
          }
        >
          {item.name}
        </div>
      ))}
    </div>
  );
}

export default RolesPastilles;
