import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
function DataDesktop() {
  const currentUser = AuthService.getCurrentUser();
  const [calf, setCalf] = useState({});
  const [isLoading, setLoading] = useState(true);

  const navigate = useNavigate();
  let { calfId } = useParams();

  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs/" + calfId, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setCalf(data);
        setLoading(false);
        console.log(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };

  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getCalf();
    }
  }, []);
  if (isLoading) {
    return <div></div>;
  }
  return (
    <div className="mb-6 flex flex-col rounded-xl border border-stone-200 shadow">
      <div className=" rounded-t-xl border-b border-stone-200 py-6 px-3">
        <span className="font-semibold">Informations</span>
      </div>
      <div className="flex flex-col p-3">
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            BDTA
          </span>
          <span className="mr-3 font-medium  text-stone-800">{calf.bdta}</span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">Nom</span>
          <span className="mr-3 font-medium  text-stone-800">{calf.name}</span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Sexe
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {calf.sex === "m" ? "Mâle" : "Femelle"}
          </span>
        </div>
        <div
          className={"flex flex-col   " + (calf.sex === "m" ? "" : "hidden")}
        >
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Castration
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {calf.castration === 0 ? "Non" : "Oui"}
          </span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Date de naissance
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {new Date(calf.birthdate).toLocaleString("default", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Heure de naissance
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {calf.birthtime}
          </span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Vitalité
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {calf.vitality}
          </span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Naissance
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {calf.birthtype}
          </span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Apport en colostrum
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {calf.colostrum} <i className="font-fira italic">l</i>
          </span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Heure de l'apport
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {calf.apporttime}
          </span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Ecornage
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {calf.ecornage ? "Oui" : "Non"}
          </span>
        </div>
        <div
          className={
            "flex flex-col  " + (calf.ecornageDate == null ? "hidden" : "")
          }
        >
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Date d'écornage
          </span>
          <span className="mr-3 font-medium  text-stone-800">
            {new Date(calf.ecornageDate).toLocaleString("default", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
        </div>
      </div>
    </div>
  );
}

export default DataDesktop;
