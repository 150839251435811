import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../../services/auth.service";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AdminTopBar from "../../components/Admin/AdminTopBar";
function AdminNewUser() {
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setisAdmin] = useState(false);
  const [password, setPassword] = useState(suggestPassword());
  const currentUser = AuthService.getCurrentUser();
  const createUser = () => {
    let roles = ["user"];
    if (isAdmin) roles.push("admin");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
      body: JSON.stringify({
        name: name,
        username: username,
        email: email,
        password: password,
        roles: roles,
      }),
    };

    fetch(process.env.REACT_APP_API_URL + "/auth/signup/", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        navigate("/admin");
      })
      .catch((error) => {
        console.error("There was an error creating the user!", error);
      });
  };
  function suggestPassword() {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var charactersLength = characters.length;
    for (let j = 0; j < 3; j++) {
      for (var i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      if (j < 2) result += "-";
    }

    return result;
  }
  const handleChange = (e) => {
    if (e.target.checked) {
      setisAdmin(true);
    } else {
      setisAdmin(false);
    }
  };
  return (
    <div>
      <AdminTopBar></AdminTopBar>
      <div className="mx-auto flex w-[1000px] flex-col bg-white px-6 pt-3 pb-6">
        <div className="my-3 flex w-fit items-center justify-center rounded-lg bg-stone-100/90 px-3 py-[2px]">
          <Link to="/admin">
            <span className="text-sm font-semibold text-stone-700">
              Liste des utilisateurs
            </span>
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-1 h-4 w-4 text-stone-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>

          <span className="text-sm text-stone-400">Nouvel utilisateur</span>
        </div>
        <div className="my-3 flex flex-col">
          <span className="text-2xl font-bold">Nouvel utilisateur</span>
          <span className="text-sm text-stone-400">
            Vous pouvez créer un compte pour un utilisateur ici.
          </span>
        </div>
        <div className=" mt-6">
          <span className="font-semibold">Informations de connexion</span>
        </div>
        <div className="my-3 w-full border-b border-stone-200"></div>
        <div className="flex">
          <div className="mr-16 w-72">
            <span className="text-sm font-medium">Prénom et nom</span>
            <p className="text-xs text-stone-400"></p>
          </div>
          <div className="grow">
            <input
              className="w-full rounded-md border border-stone-300 py-2 px-3 text-sm text-stone-600 shadow"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="my-3 w-full border-b border-stone-200"></div>
        <div className="flex">
          <div className="mr-16 w-72">
            <span className="text-sm font-medium">Nom d'utilisateur</span>
            <p className="text-xs text-stone-400">
              Le nom d'utilisateur est utilisé pour se connecté à l'application
            </p>
          </div>
          <div className="grow">
            <input
              className="w-full rounded-md border border-stone-300 py-2 px-3 text-sm text-stone-600 shadow"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            ></input>
          </div>
        </div>
        <div className="my-3 w-full border-b border-stone-200"></div>
        <div className="flex">
          <div className="mr-16 w-72">
            <span className="text-sm font-medium">Adresse éléctronique</span>
            <p className="text-xs text-stone-400">
              L'adresse E-Mail d'un utilisateur n'est pour l'instant pas
              tellement utilisé...
            </p>
          </div>
          <div className="grow">
            <input
              className="w-full rounded-md border border-stone-300 py-2 px-3 text-sm text-stone-600 shadow"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></input>
          </div>
        </div>

        <div className=" mt-12">
          <span className="font-semibold">Rôles</span>
        </div>
        <div className="my-3 w-full border-b border-stone-200"></div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="admin_role"
            name="admin_role"
            className="h-4 w-4 appearance-none rounded-[4px] accent-amber-500"
            checked={isAdmin}
            onChange={handleChange}
          ></input>
          <label htmlFor="admin_role" className="ml-2 text-sm font-medium">
            Administrateur
          </label>
        </div>
        <div className=" mt-12">
          <span className="font-semibold">Mot de passe</span>
        </div>
        <div className="my-3 w-full border-b border-stone-200"></div>
        <div className="mt-3 flex">
          <div className="mr-16 w-72">
            <span className="text-sm font-medium">Créer un mot de passe</span>
            <p className="text-xs text-stone-400">
              Vous devez saisir un mot de passe pour vos utilisateurs, ils ne
              pourront pas le changer.
            </p>
          </div>
          <div className="flex h-fit grow">
            <input
              className="w-full rounded-l-md border border-stone-300 py-2 px-3 text-sm text-stone-600 shadow"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></input>
            <button
              className="mr-3 rounded-r-md border-y border-r border-stone-300 bg-white py-2 px-3 text-sm font-medium text-stone-600 shadow hover:bg-stone-50"
              onClick={(e) => setPassword(suggestPassword())}
            >
              Suggestion
            </button>
          </div>
        </div>
        <div className="mt-6 flex justify-end">
          <button
            className="rounded-md border border-amber-500 bg-amber-400 px-6 py-2 text-sm font-medium text-white shadow hover:shadow-inner"
            onClick={() => createUser()}
          >
            Créer
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminNewUser;
