import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
function UpdateWeightDesktop(props) {
  const [weight, setWeight] = useState({});
  const [calf, setCalf] = useState({});
  const [previousWeight, setPreviousWeight] = useState({});
  const [GMQ_interval, setGMQ_interval] = useState(0);
  const [GMQ_birth, setGMQ_birth] = useState(0);
  const navigate = useNavigate();

  const getWeight = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/weight/" + props.selectedWeight,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setWeight(data);
        getPreviousWeight(data.fk_calf, data.date);
      })
      .catch((error) => {
        console.error("There was an error getting the weight", error);
      });
  };
  const getPreviousWeight = (calfID, date) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const myDate = new Date(date).toISOString().substring(0, 10);
    fetch(
      process.env.REACT_APP_API_URL +
        "/weight/previous/" +
        props.calfID +
        "/" +
        myDate,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setPreviousWeight(data);
      })
      .catch((error) => {
        console.error("There was an error getting the previous weight", error);
      });
  };
  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/calfs/" + props.calfID,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setCalf(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  function calcGMQ(initialWeight, currentWeight, days) {
    return (currentWeight - initialWeight) / days;
  }
  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/weight/" + props.selectedWeight,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        props.refreshComponent();
        props.closeModal();
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getWeight();
      getCalf();
    }
  }, []);
  useEffect(() => {
    const new_GMQ_interval = calcGMQ(
      previousWeight.weight,
      weight.weight,
      (new Date(weight.date).getTime() -
        new Date(previousWeight.date).getTime()) /
        (1000 * 60 * 60 * 24)
    ).toFixed(2);
    setGMQ_interval(new_GMQ_interval);
  }, [previousWeight]);
  useEffect(() => {
    console.log(weight);
    const new_GMQ_birth = calcGMQ(
      calf.birthweight,
      weight.weight,
      (new Date(weight.date).getTime() - new Date(calf.birthdate).getTime()) /
        (1000 * 60 * 60 * 24)
    ).toFixed(2);
    setGMQ_birth(new_GMQ_birth);
  }, [calf]);
  return (
    <div className="">
      <div className="md:flex md:flex-col md:items-center">
        <div className="flex w-full flex-col ">
          <div className="mx-3 rounded-lg bg-white md:bg-stone-100">
            <div className="ml-3 flex flex-col">
              <div className="flex items-center justify-between border-b">
                <span className="py-2 text-sm font-medium">Poids</span>
                <span className="mr-3 text-sm text-stone-400">
                  {weight.weight} kg
                </span>
              </div>
              <div className="flex items-center justify-between">
                <span className="py-2 text-sm font-medium">
                  Date de la peser
                </span>
                <span className="mr-3 text-sm text-stone-400">
                  {new Date(weight.date).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
              </div>
            </div>
          </div>
          <div className="mx-3 mt-3 rounded-lg bg-stone-200 pl-3">
            <div className="flex items-center justify-between border-b border-stone-300">
              <span className="py-2 text-sm font-medium text-stone-600">
                GMQ avec le poids précédent
              </span>
              <span
                className={
                  "mr-3 rounded-full px-2 text-sm font-medium text-stone-400 " +
                  (GMQ_interval > 0.75
                    ? "bg-emerald-100 text-emerald-500"
                    : "bg-red-100 text-red-600")
                }
              >
                {GMQ_interval} Kg/J
              </span>
            </div>
            <div className="flex items-center justify-between border-b border-stone-300">
              <span className="py-2 text-sm font-medium text-stone-600">
                Poids précédent
              </span>
              <span className="mr-3 text-sm text-stone-400">
                {previousWeight.weight}
                Kg
              </span>
            </div>
            <div className="flex items-center justify-between border-b border-stone-300">
              <span className="py-2 text-sm font-medium text-stone-600">
                GMQ avec le poids de naissance
              </span>
              <span
                className={
                  "mr-3 rounded-full px-2 text-sm font-medium text-stone-400 " +
                  (GMQ_birth > 0.75
                    ? "bg-emerald-100 text-emerald-500"
                    : "bg-red-100 text-red-600")
                }
              >
                {GMQ_birth} Kg/J
              </span>
            </div>
            <div className="flex items-center justify-between">
              <span className="py-2 text-sm font-medium text-stone-600">
                Poids à la naissance
              </span>
              <span className="mr-3 text-sm text-stone-400">
                {calf.birthweight}
                Kg
              </span>
            </div>
          </div>
          <div className="mt-3 px-3 md:self-end">
            <button
              className="w-full rounded-lg bg-white py-2 font-semibold text-red-500 shadow transition-colors duration-200 md:w-fit md:bg-red-600 md:px-3 md:text-white md:hover:bg-red-700"
              onClick={() => handleDelete()}
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateWeightDesktop;
