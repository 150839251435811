import React from "react";
import { Link } from "react-router-dom";
import SlideMenu from "../components/SlideMenu";
import { useState } from "react";
function TopBar() {
  const [isDisplay, setDisplay] = useState(false);
  const handleClose = () => {
    setDisplay(!isDisplay);
  };
  return (
    <div className="mb-3 flex h-16 items-center justify-center border-b border-stone-200 bg-white">
      <SlideMenu
        isDisplay={isDisplay}
        setDisplay={handleClose}
        setIsDisplay={setDisplay}
      ></SlideMenu>
      <div className="grow"></div>
      <div className="w-[1400px]">
        <Link to="/">
          <div className="flex cursor-pointer items-center">
            <div className="mr-3">
              <img src="/logo_header.png" className="h-12"></img>
            </div>
            <div className="flex flex-col">
              <span className="font-fira text-2xl font-bold">Check'veau</span>
              <span className="-translate-y-1 text-xs text-stone-500">
                Par Grangeneuve
              </span>
            </div>
          </div>
        </Link>{" "}
      </div>
      <div className="mr-3 flex grow justify-end">
        <button onClick={() => setDisplay(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default TopBar;
