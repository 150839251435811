import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../../services/auth.service";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import AdminTopBar from "../../components/Admin/AdminTopBar";
import AdminCalfList from "../../components/Admin/AdminCalfList";
import AdminUserDetail from "../../components/Admin/AdminUserDetails";
import { Dialog, Transition } from "@headlessui/react";
import CreateCalfDesktop from "../../components/CreateCalfDesktop";

function Admin() {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  if (!currentUser.roles.includes("ROLE_ADMIN")) navigate("/");
  let { userId } = useParams();
  let [isCreateCalfOpen, setIsCreateCalfOpen] = useState(false);
  const [user, setUser] = useState({});
  const [search, setSearch] = useState("");
  const [listCalfs, setListCalfs] = useState([]);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isDisplay, setDisplay] = useState(false);

  const getUser = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/user/" + userId, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setUser(data);
      })
      .catch((error) => {
        console.error("There was an error getting the users!", error);
      });
  };
  function closeCreateCalfModal() {
    setIsCreateCalfOpen(false);
  }

  function openCreateCalfModal() {
    setIsCreateCalfOpen(true);
  }
  const getCalfs = async (userId) => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/calfs/user/" + userId
      );
      const jsonData = await response.json();
      setListCalfs(jsonData);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
    }
  };
  const extractData = async (userId) => {
    setDownloadLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/calfs/csv/" + userId,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        let url = process.env.REACT_APP_API_URL + "/public/" + data.link;
        let a = document.createElement("a");
        a.href = url;
        a.download = currentUser.username + "_liste_veaux.csv";
        a.click();

        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("There was an error extracting data", error);
        setDownloadLoading(false);
      });
  };
  const refreshCalfs = () => {
    getCalfs(userId);
  };
  const handleClose = () => {
    setDisplay(!isDisplay);
  };

  useEffect(() => {
    //Runs only on the first render
    getUser();
    getCalfs(userId);
  }, []);
  return (
    <div className="flex flex-col text-sm">
      <AdminTopBar></AdminTopBar>
      <div className="flex flex-col self-center md:w-[1200px]">
        <div className="mb-3 flex w-fit items-center justify-center rounded-lg bg-stone-200/60 px-3 py-1 text-sm">
          <Link to={"/admin/"}>
            <span className="text-sm font-medium text-stone-500">
              Tous les utilisateurs
            </span>
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="mr-1 h-4 w-4 text-stone-300"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
          <Link to={"/admin/user/" + user.id}>
            <span className="text-sm font-semibold text-stone-500">
              {user.nom}
            </span>
          </Link>
        </div>
        <div className="mb-3 flex justify-between">
          <div>
            <span className="text-2xl font-bold">
              Tableau de bord de {user.nom}
            </span>
          </div>

          <div className="flex">
            <input
              className="mr-3 w-full rounded-md border border-stone-300 px-3 py-2 text-sm md:w-64 md:self-end"
              placeholder="Chercher"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
            <button
              onClick={() => extractData(userId)}
              className="mr-3 flex rounded-md border border-stone-300 px-3 py-2 text-sm text-stone-800 shadow transition-all duration-100 hover:shadow-inner"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-2 h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 9.75v6.75m0 0l-3-3m3 3l3-3m-8.25 6a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                />
              </svg>
              <span>Extraire les données</span>
            </button>{" "}
            <button
              onClick={() => openCreateCalfModal()}
              className="flex rounded-md border border-amber-500 bg-amber-400 px-3 py-2 text-sm text-white shadow transition-all duration-100 hover:shadow-inner"
            >
              Ajouter un veau
            </button>
          </div>
        </div>
        <div className="mb-6 w-full border-b border-stone-200"></div>
        <div className="flex">
          <div className="pr-3 md:w-1/3">
            <AdminUserDetail user={user}></AdminUserDetail>
          </div>
          <div className="pl-3 md:w-2/3">
            <AdminCalfList
              search={search}
              listCalfs={listCalfs}
              isLoading={isLoading}
            ></AdminCalfList>
          </div>
        </div>
      </div>
      <Transition appear show={isCreateCalfOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeCreateCalfModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Ajouter un nouveau veau
                  </Dialog.Title>
                  <div className="mt-2">
                    <CreateCalfDesktop
                      closeModal={closeCreateCalfModal}
                      getCalfs={refreshCalfs}
                    ></CreateCalfDesktop>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default Admin;
