import React, { Component } from "react";
import AuthService from "./services/auth.service";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import NewCalf from "./pages/NewCalf";
import Calf from "./pages/Calf";

// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import NewWeight from "./pages/NewWeight";
import NewDisease from "./pages/NewDisease";
import Disease from "./pages/Disease";
import Weight from "./pages/Weight";
import UpdateCalf from "./pages/UpdateCalf";
import UpdateDisease from "./pages/UpdateDisease";
import Vaccine from "./pages/Vaccine";
import NewVaccine from "./pages/NewVaccine";
import UpdateVaccine from "./pages/UpdateVaccine";
import PrintCalfReport from "./pages/PrintCalfReport";
import Admin from "./pages/Admin/Admin";
import UpdateUser from "./pages/Admin/UpdateUser";
import AdminNewUser from "./pages/Admin/AdminNewUser";
import AdminUserHub from "./pages/Admin/AdminUserHub";
import AdminCalf from "./components/Admin/AdminCalf";
class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      showUserBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    };
  }

  componentDidMount() {
    const user = AuthService.getCurrentUser();

    if (user) {
      console.log(user);
      this.setState({
        currentUser: user,
        showUserBoard: user.roles.includes("ROLE_USER"),
        showAdminBoard: user.roles.includes("ROLE_ADMIN"),
      });
    }

    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
    });
  }
  render() {
    const { currentUser, showUserBoard, showAdminBoard } = this.state;
    return (
      <div className="App selection:bg-amber-300 selection:text-amber-900">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Login" element={<Login />} />
          <Route path="Nouveau" element={<NewCalf />} />
          <Route path="veaux/:calfId" element={<Calf />} />
          <Route path="modifier_veau/:id" element={<UpdateCalf />} />
          <Route path="poids/:id" element={<NewWeight />} />
          <Route path="nouvelle_maladie/:id" element={<NewDisease />} />
          <Route
            path="modifier_maladie/:calfId/:id"
            element={<UpdateDisease />}
          />
          <Route path="maladie/:calfId/:id" element={<Disease />} />
          <Route path="vaccin/:calfId/:id" element={<Vaccine />} />
          <Route path="nouveau_vaccin/:id" element={<NewVaccine />} />
          <Route
            path="modifier_vaccin/:calfId/:id"
            element={<UpdateVaccine />}
          />
          <Route path="poids/:calfId/:id" element={<Weight />} />
          <Route path="print/:id" element={<PrintCalfReport />} />

          <Route path="nouvel_utilisateur" element={<AdminNewUser />} />
          <Route path="admin">
            <Route index element={<Admin />} />
            <Route path="user/update/:id" element={<UpdateUser />} />
            <Route path="user/:userId" element={<AdminUserHub />} />
            <Route path="user/calf/:calfId" element={<AdminCalf />} />
          </Route>
        </Routes>
        <footer>
          <div className="h-40 "></div>
        </footer>
      </div>
    );
  }
}

export default App;
