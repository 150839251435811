import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
function Vaccine() {
  const [vaccine, setVaccine] = useState({});
  const navigate = useNavigate();
  let { calfId, id } = useParams();
  const getVaccine = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/vaccine/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setVaccine(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/vaccine/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        navigate("/veaux/" + calfId, { state: { from: "health" } });
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getVaccine();
    }
  }, []);
  return (
    <div>
      <TopBar link={"/veaux/" + calfId} state={{ from: "vaccin" }}></TopBar>
      <div className="bg-white rounded-lg mt-3 mx-3">
        <div className="flex flex-col ml-3">
          <div className="flex justify-between items-center border-b">
            <span className="py-2 text-sm">Date du vaccin</span>
            <span className="text-stone-400 text-sm mr-3">
              {new Date(vaccine.date).toLocaleString("default", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>

          <div className="flex justify-between items-center">
            <span className="py-2 text-sm">Nom</span>
            <span className="text-stone-400 text-sm mr-3">{vaccine.name}</span>
          </div>
        </div>
      </div>
      <div className="px-3 mt-3">
        <Link to={"/modifier_vaccin/" + calfId + "/" + id}>
          <button className="bg-amber-400 rounded-lg text-white shadow font-semibold py-2 w-full">
            Modifier
          </button>
        </Link>
      </div>
      <div className="px-3 mt-3">
        <button
          className="bg-white rounded-lg text-red-500 shadow font-semibold py-2 w-full"
          onClick={() => handleDelete()}
        >
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Vaccine;
