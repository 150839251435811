import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import TopBar from "../components/TopBar";
import Calf from "./Calf";
import { set } from "date-fns";
function UpdateCalf() {
  const [pk_calf, setPK_calf] = useState(0);
  const [bdta, setBdta] = useState("");
  const [name, setName] = useState("");
  const [sex, setSex] = useState("f");
  const [birthdate, setBirthdate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [birthtime, setBirthtime] = useState("");
  const [vitality, setVitality] = useState("vif");
  const [birthtype, setBirthtype] = useState("Sans aide");
  const [colostrum, setColostrum] = useState("");
  const [apporttime, setApporttime] = useState("");
  const [ecornage, setEcornage] = useState(false);
  const [ecornageDate, setEcornageDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [castration, setCastration] = useState(false);
  const [weight, setWeight] = useState("");
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const [isLoading, setLoading] = useState(true);
  let { id } = useParams();
  const onClick = (event) => {
    event.preventDefault();
    setEcornage(!ecornage);
  };
  const onClickCastration = (event) => {
    event.preventDefault();
    setCastration(!castration);
  };
  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setPK_calf(data.pk_calf);
        setBdta(data.bdta);
        setName(data.name);
        setSex(data.sex);
        setBirthdate(new Date(data.birthdate).toISOString().substring(0, 10));
        setBirthtime(data.birthtime);
        setVitality(data.vitality);
        setBirthtype(data.birthtype);
        setColostrum(data.colostrum);
        setApporttime(data.apporttime);
        setWeight(data.weight);
        setEcornage(data.ecornage);
        setEcornageDate(data.ecornageDate);
        setCastration(data.castration);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const handleSubmit = (event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        pk_calf: pk_calf,
        fk_user: currentUser.id,
        bdta: bdta,
        name: name,
        sex: sex,
        birthdate: new Date(birthdate).toISOString().substring(0, 10),
        birthtime: birthtime,
        vitality: vitality,
        birthtype: birthtype,
        colostrum: colostrum,
        apporttime: apporttime,
        ecornage: ecornage,
        ecornageDate: ecornage ? ecornageDate : null,
        castration: sex === "f" ? false : castration,
      }),
    };
    fetch(process.env.REACT_APP_API_URL + "/calfs/" + pk_calf, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }

        navigate("/veaux/" + pk_calf, { state: { from: "data" } });
      })
      .catch((error) => {
        console.error("There was an error updating a calf!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getCalf();
    }
  }, []);
  return (
    <div>
      <TopBar link={"/veaux/" + pk_calf} state={{ from: "data" }}></TopBar>
      <Link to={"/veaux/" + id}>
        <button className="mx-3 my-3 flex font-medium text-stone-500">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
            />
          </svg>
          {"Retour"}
        </button>
      </Link>
      <div className="mx-3 rounded-lg bg-white px-3 pt-2">
        <div className="mb-3 flex flex-col">
          <span className="text-2xl font-bold">Modifier un veau</span>
          <p className="text-sm text-stone-400">
            Vous pouvez modifier un nouveau veau à votre liste ici.
          </p>
        </div>

        <form onSubmit={handleSubmit} className="flex w-full flex-col">
          <label
            htmlFor="bdta_input"
            className="text-sm font-medium text-stone-700"
          >
            BDTA
          </label>
          <input
            className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
            type="text"
            value={bdta}
            onChange={(e) => setBdta(e.target.value)}
            placeholder="BDTA"
            name="bdta_input"
          ></input>

          <label
            htmlFor="name_input"
            className="text-sm font-medium text-stone-700"
          >
            Nom
          </label>
          <input
            className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Nom"
            name="name_input"
          ></input>

          <label
            htmlFor="sex_input"
            className="text-sm font-medium text-stone-700"
          >
            Sexe
          </label>
          <select
            className="mb-3 rounded-md border border-stone-300 bg-stone-50 p-3"
            value={sex}
            onChange={(e) => setSex(e.target.value)}
            name="sex_input"
          >
            <option value="f">Femelle</option>
            <option value="m">Mâle</option>
          </select>
          <div
            className={"my-3 flex flex-row " + (sex === "f" ? "hidden" : "")}
          >
            <span className="mr-10 text-sm font-medium text-stone-700">
              Castré
            </span>
            <button
              className={
                "flex  h-8 w-14 items-center rounded-full p-1 ring-1 ring-inset transition-colors duration-200 ease-in-out " +
                (castration
                  ? "bg-amber-500 ring-black/20"
                  : "bg-stone-300 ring-slate-900/5")
              }
              onClick={(e) => onClickCastration(e)}
            >
              <span
                className={
                  "inline-block h-6 w-6 transform rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out " +
                  (castration ? "translate-x-6" : "")
                }
              ></span>
            </button>
          </div>
          <label
            htmlFor="birthday_input"
            className="text-sm font-medium text-stone-700"
          >
            Date de naissance
          </label>
          <input
            className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
            type="date"
            value={birthdate}
            onChange={(e) => setBirthdate(e.target.value)}
            placeholder="Date de naissance"
            name="birthday_input"
          ></input>

          <label
            htmlFor="birthtime_input"
            className="text-sm font-medium text-stone-700"
          >
            Heure de naissance
          </label>
          <input
            className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
            type="time"
            value={birthtime}
            onChange={(e) => setBirthtime(e.target.value)}
            placeholder="Heure de naissance"
            name="birthtime_input"
          ></input>
          <label
            htmlFor="vitality_input"
            className="text-sm font-medium text-stone-700"
          >
            Vitalité
          </label>
          <select
            className="mb-3 rounded-md border border-stone-300 bg-stone-50 p-3"
            value={vitality}
            onChange={(e) => setVitality(e.target.value)}
            name="vitality_input"
          >
            <option value="vif">Vif</option>
            <option value="apathique">Apathique</option>
            <option value="mort">Mort</option>
          </select>
          <label
            htmlFor="weight_input"
            className="text-sm font-medium text-stone-700"
          >
            Poid à la naissance
          </label>
          <input
            className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
            type="text"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            placeholder="Heure de naissance"
            name="weight_input"
          ></input>
          <label
            htmlFor="birthtype_input"
            className="text-sm font-medium text-stone-700"
          >
            Déroulement de la naissance
          </label>
          <select
            className="mb-3 rounded-md border border-stone-300 bg-stone-50 p-3"
            value={birthtype}
            onChange={(e) => setBirthtype(e.target.value)}
            name="birthtype_input"
          >
            <option value="sans aide">Sans aide</option>
            <option value="aide faible">Aide faible</option>
            <option value="naissance difficile">Naissance difficile</option>
          </select>
          <label
            htmlFor="colostrum_input"
            className="text-sm font-medium text-stone-700"
          >
            1er apport en colostrum
          </label>
          <div className="mb-3 flex items-center justify-between rounded-lg border border-stone-300 bg-stone-100">
            <input
              className="grow bg-transparent p-3 text-left text-stone-500"
              type="text"
              value={colostrum}
              onChange={(e) => setColostrum(e.target.value)}
              placeholder="Apport en colostrum"
              name="colostrum_input"
            ></input>
            <div className=" mr-3 text-stone-500">
              <span className="font-fira italic">l</span>
            </div>
          </div>
          <label
            htmlFor="apporttime_input"
            className="text-sm font-medium text-stone-700"
          >
            Heure de l'apport
          </label>
          <input
            className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
            type="time"
            value={apporttime}
            onChange={(e) => setApporttime(e.target.value)}
            placeholder="Heure de l'apport"
            name="apporttime_input"
          ></input>
          <div className="my-3 flex flex-row">
            <span className="mr-10 text-sm font-medium text-stone-700">
              Ecornage
            </span>
            <button
              className={
                "flex  h-8 w-14 items-center rounded-full p-1 ring-1 ring-inset transition-colors duration-200 ease-in-out " +
                (ecornage
                  ? "bg-amber-500 ring-black/20"
                  : "bg-stone-300 ring-slate-900/5")
              }
              onClick={(e) => onClick(e)}
            >
              <span
                className={
                  "inline-block h-6 w-6 transform rounded-full bg-white shadow-sm ring-1 ring-slate-700/10 transition duration-200 ease-in-out " +
                  (ecornage ? "translate-x-6" : "")
                }
              ></span>
            </button>
          </div>
          <div className={ecornage ? "" : "hidden"}>
            <label
              htmlFor="ecornageDate_input"
              className="text-sm font-medium text-stone-700"
            >
              Date d'écornage
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
              type="date"
              value={ecornageDate}
              onChange={(e) => setEcornageDate(e.target.value)}
              placeholder="Date d'ecornage'"
              name="ecornageDate_input"
            ></input>
          </div>
          <button
            type="submit"
            className="my-3 mb-3 w-full rounded-lg border border-amber-500 bg-amber-400 p-3 font-medium text-white"
          >
            Enregistrer
          </button>
        </form>
      </div>
    </div>
  );
}

export default UpdateCalf;
