import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import NewDiseaseDesktop from "./DiseaseCreateDesktop";
import UpdateDiseaseDesktop from "./UpdateDiseaseDesktop";
function HealthDesktop() {
  const [diseases, setDiseases] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  let { calfId } = useParams();
  let [isOpen, setIsOpen] = useState(false);
  let [isUpdateDiseaseOpen, setIsUpdateDiseaseOpen] = useState(false);
  let [selectedDisease, setSelectedDisease] = useState(0);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  function closeUpdatediseaseModal() {
    setIsUpdateDiseaseOpen(false);
  }

  function openUpdatediseaseModal(calfId) {
    setSelectedDisease(calfId);
    setIsUpdateDiseaseOpen(true);
  }

  const getDiseases = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API_URL + "/health/calf/" + calfId,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setDiseases(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };

  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getDiseases();
    }
  }, []);
  if (isLoading) {
    return <div></div>;
  }
  return (
    <div className="my-6 rounded-xl border border-stone-200 shadow">
      <div className="flex items-center justify-between rounded-t-xl border-b border-stone-200 py-3 px-3">
        <div>
          <span className="font-semibold">Maladies</span>
        </div>
        <div>
          <button
            onClick={openModal}
            className="flex items-center rounded-lg border border-stone-200 px-3 py-2 text-sm text-stone-800 shadow transition-all duration-100 hover:shadow-inner"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-2 h-4 w-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>

            <span>Ajouter</span>
          </button>
        </div>
      </div>
      <div className={diseases.length > 0 ? "" : "hidden"}>
        <div className="">
          {diseases.map((disease, index) => (
            <div
              className={
                "ml-3 flex cursor-pointer items-center py-2 transition-all duration-100 hover:translate-x-1" +
                (index === diseases.length - 1 ? "" : " border-b")
              }
              key={"key_" + disease.pk_health}
              onClick={() => openUpdatediseaseModal(disease.pk_health)}
            >
              <div className="flex w-2/5 flex-col">
                <span>{disease.cause}</span>
                <span className="text-sm text-stone-400">
                  {new Date(disease.start_date).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
              </div>
              <div className="flex w-1/3 justify-center">
                <div
                  className={
                    "w-fit rounded-full bg-emerald-100 px-2 " +
                    (disease.end_date ? "" : "hidden")
                  }
                >
                  <span className="font-medium text-emerald-600">Guérie</span>
                </div>
                <div
                  className={
                    "w-fit rounded-full bg-red-100 px-2 " +
                    (disease.end_date ? "hidden" : "")
                  }
                >
                  <span className="font-medium text-red-600">Malade</span>
                </div>
              </div>
              <div className="m-3 flex grow justify-end">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="h-5 w-5 text-stone-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 4.5l7.5 7.5-7.5 7.5"
                  />
                </svg>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={
          "flex flex-col items-center justify-center py-6 " +
          (diseases.length === 0 ? "" : "hidden")
        }
      >
        <span className="font-semibold">Ajoutez une maladie</span>
        <p className="my-3 text-center text-sm text-stone-500">
          Il n'y a aucune maladie enregistré pour ce veau. Cliquez sur "Ajouter"
          pour ajouter une maladie.
        </p>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Ajouter une maladie
                  </Dialog.Title>
                  <div className="mt-2">
                    <NewDiseaseDesktop
                      closeModal={closeModal}
                      getDiseases={getDiseases}
                      calfID={calfId}
                    ></NewDiseaseDesktop>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isUpdateDiseaseOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeUpdatediseaseModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Modifier une maladie
                  </Dialog.Title>
                  <div className="mt-2">
                    <UpdateDiseaseDesktop
                      closeModal={closeUpdatediseaseModal}
                      getDiseases={getDiseases}
                      diseaseId={selectedDisease}
                    ></UpdateDiseaseDesktop>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default HealthDesktop;
