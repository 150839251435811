import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
function Disease() {
  const [weight, setWeight] = useState({});
  const [calf, setCalf] = useState({});
  const [previousWeight, setPreviousWeight] = useState({});
  const [GMQ_interval, setGMQ_interval] = useState(0);
  const [GMQ_birth, setGMQ_birth] = useState(0);
  const navigate = useNavigate();
  let { calfId, id } = useParams();
  const getWeight = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/weight/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setWeight(data);
        getPreviousWeight(data.fk_calf, data.date);
      })
      .catch((error) => {
        console.error("There was an error getting the weight", error);
      });
  };
  const getPreviousWeight = (calfID, date) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    const myDate = new Date(date).toISOString().substring(0, 10);
    fetch(
      process.env.REACT_APP_API_URL +
        "/weight/previous/" +
        calfID +
        "/" +
        myDate,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setPreviousWeight(data);
      })
      .catch((error) => {
        console.error("There was an error getting the previous weight", error);
      });
  };
  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs/" + calfId, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setCalf(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  function calcGMQ(initialWeight, currentWeight, days) {
    return (currentWeight - initialWeight) / days;
  }
  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/weight/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        navigate("/veaux/" + calfId);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getWeight();
      getCalf();
    }
  }, []);
  useEffect(() => {
    const new_GMQ_interval = calcGMQ(
      previousWeight.weight,
      weight.weight,
      (new Date(weight.date).getTime() -
        new Date(previousWeight.date).getTime()) /
        (1000 * 60 * 60 * 24)
    ).toFixed(2);
    setGMQ_interval(new_GMQ_interval);
  }, [previousWeight]);
  useEffect(() => {
    const new_GMQ_birth = calcGMQ(
      calf.birthweight,
      weight.weight,
      (new Date(weight.date).getTime() - new Date(calf.birthdate).getTime()) /
        (1000 * 60 * 60 * 24)
    ).toFixed(2);
    setGMQ_birth(new_GMQ_birth);
  }, [calf]);
  return (
    <div className="">
      <TopBar link={"/veaux/" + calfId}></TopBar>
      <div className="md:flex md:flex-col md:items-center">
        <div className="flex md:w-[800px] mb-3 ml-3">
          <div className="flex justify-center items-center px-3 py-1 bg-stone-200/60 rounded-lg">
            <Link to="/">
              <span className="font-semibold text-stone-700 text-sm">
                Mes Veaux
              </span>
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-stone-300 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
            <Link to={"/veaux/" + calfId}>
              <span className=" font-semibold text-stone-700 text-sm">
                {calf.name}
              </span>
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-stone-300 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
            <span className="text-stone-400 text-sm">
              {new Date(weight.date).toLocaleString("default", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>
        </div>
        <div className="flex flex-col md:bg-white md:w-[800px] md:rounded-lg md:pb-3 md:shadow-lg">
          <div className="bg-white md:bg-stone-100 rounded-lg mx-3">
            <div className="flex flex-col ml-3">
              <div className="flex justify-between items-center border-b">
                <span className="py-2 text-sm font-medium">Poids</span>
                <span className="text-stone-400 text-sm mr-3">
                  {weight.weight} kg
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="py-2 text-sm font-medium">
                  Date de la peser
                </span>
                <span className="text-stone-400 text-sm mr-3">
                  {new Date(weight.date).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
                </span>
              </div>
            </div>
          </div>
          <div className="mx-3 mt-3 pl-3 bg-stone-200 rounded-lg">
            <div className="flex justify-between items-center border-b border-stone-300">
              <span className="py-2 text-sm text-stone-600 font-medium">
                GMQ avec le poids précédent
              </span>
              <span
                className={
                  "text-stone-400 text-sm mr-3 font-medium rounded-full px-2 " +
                  (GMQ_interval > 0.75
                    ? "text-emerald-500 bg-emerald-100"
                    : "text-red-600 bg-red-100")
                }
              >
                {GMQ_interval} Kg/J
              </span>
            </div>
            <div className="flex justify-between items-center border-b border-stone-300">
              <span className="py-2 text-sm text-stone-600 font-medium">
                Poids précédent
              </span>
              <span className="text-stone-400 text-sm mr-3">
                {previousWeight.weight}
                Kg
              </span>
            </div>
            <div className="flex justify-between items-center border-b border-stone-300">
              <span className="py-2 text-sm text-stone-600 font-medium">
                GMQ avec le poids de naissance
              </span>
              <span
                className={
                  "text-stone-400 text-sm mr-3 font-medium rounded-full px-2 " +
                  (GMQ_birth > 0.75
                    ? "text-emerald-500 bg-emerald-100"
                    : "text-red-600 bg-red-100")
                }
              >
                {GMQ_birth} Kg/J
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="py-2 text-sm text-stone-600 font-medium">
                Poids à la naissance
              </span>
              <span className="text-stone-400 text-sm mr-3">
                {calf.birthweight}
                Kg
              </span>
            </div>
          </div>
          <div className="px-3 mt-3 md:self-end">
            <button
              className="bg-white rounded-lg text-red-500 shadow font-semibold py-2 w-full md:w-fit md:px-3 md:bg-red-600 md:hover:bg-red-700 transition-colors duration-200 md:text-white"
              onClick={() => handleDelete()}
            >
              Supprimer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Disease;
