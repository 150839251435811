import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import RolesPastilles from "../RolesPastilles";

function AdminUserDetail(props) {
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  if (!currentUser.roles.includes("ROLE_ADMIN")) navigate("/");
  let { userId } = useParams();
  let user = props.user;

  return (
    <div className="mb-6 flex flex-col rounded-xl border border-stone-200">
      <div className=" rounded-t-xl border-b border-stone-200 py-6 px-3">
        <div className="flex items-center justify-between">
          <span className="font-semibold">Informations</span>
          <Link to={"/admin/user/update/" + user.id}>
            <button className="flex items-center rounded-lg border border-stone-200 px-3 py-2 text-sm text-stone-800 shadow transition-all duration-100 hover:shadow-inner">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-2 h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>

              <span>Modifier</span>
            </button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col p-3">
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">Nom</span>
          <span className="mr-3 font-semibold  text-stone-800">{user.nom}</span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Nom d'utilisateur
          </span>
          <span className="mr-3 font-semibold  text-stone-800">
            {user.username}
          </span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            E-Mail
          </span>
          <span className="mr-3 font-semibold  text-stone-800">
            {user.email}
          </span>
        </div>
        <div className="flex flex-col  ">
          <span className="py-2  text-sm font-medium text-stone-400 ">
            Rôles
          </span>
          <RolesPastilles id={userId}></RolesPastilles>
        </div>
      </div>
    </div>
  );
}

export default AdminUserDetail;
