import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import TopBar from "../components/TopBar";
function CreateCalfDesktop(props) {
  const [bdta, setBdta] = useState("");
  const [name, setName] = useState("");
  const [sex, setSex] = useState("f");
  const [birthdate, setBirthdate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [birthtime, setBirthtime] = useState("");
  const [vitality, setVitality] = useState("vif");
  const [birthtype, setBirthtype] = useState("Sans aide");
  const [colostrum, setColostrum] = useState("");
  const [colostrumMean, setColostrumMean] = useState("");
  const [colostrumSource, setColostrumSource] = useState("");
  const [colostrumContent_fer, setColostrumContent_fer] = useState(false);
  const [colostrumContent_selenium, setColostrumContent_selenium] =
    useState(false);
  const [colostrumContent_locatim, setColostrumContent_locatim] =
    useState(false);
  const [colostrumQuality, setColostrumQuality] = useState(null);
  const [apporttime, setApporttime] = useState("");
  const [vaccine, setVaccine] = useState("");
  const [vaccinated, setVaccinated] = useState(false);
  const [ecornage, setEcornage] = useState(false);
  const [ecornageDate, setEcornageDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [castration, setCastration] = useState(false);
  const [weight, setWeight] = useState("");
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  const handleSubmit = (event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();
    if (sex === "f") {
      setCastration(false);
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        fk_user: currentUser.id,
        bdta: bdta,
        name: name,
        sex: sex,
        birthdate: birthdate,
        birthtime: birthtime,
        vitality: vitality,
        birthtype: birthtype,
        colostrum: colostrum,
        colostrumMean: colostrumMean,
        colostrumSource: colostrumSource,
        colostrumContent_fer: colostrumContent_fer,
        colostrumContent_selenium: colostrumContent_selenium,
        colostrumContent_locatim: colostrumContent_locatim,
        colostrumQuality: colostrumQuality,
        apporttime: apporttime,
        ecornage: ecornage,
        ecornageDate: ecornage ? ecornageDate : null,
        castration: castration,
      }),
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        let birth_weight = parseFloat(weight.replace(",", "."));
        const requestOptionsWeight = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            date: data.birthdate,
            weight: birth_weight,
            fk_calf: data.id,
          }),
        };
        fetch(process.env.REACT_APP_API_URL + "/weight/", requestOptionsWeight)
          .then(async (response) => {
            const isJson = response.headers
              .get("content-type")
              ?.includes("application/json");
            const dataWeight = isJson && (await response.json());

            // check for error response
            if (!response.ok) {
              // get error message from body or default to response status
              const error =
                (dataWeight && dataWeight.message) || response.status;
              return Promise.reject(error);
            }
            props.getCalfs();
            props.closeModal();
          })
          .catch((error) => {
            console.error("There was an error creating a weigth!", error);
          });
        const requestOptionsVaccine = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            date: data.birthdate,
            name: vaccine,
            fk_calf: data.id,
          }),
        };
        if (vaccinated) {
          fetch(
            process.env.REACT_APP_API_URL + "/vaccine/",
            requestOptionsVaccine
          )
            .then(async (response) => {
              const isJson = response.headers
                .get("content-type")
                ?.includes("application/json");
              const dataWeight = isJson && (await response.json());

              // check for error response
              if (!response.ok) {
                // get error message from body or default to response status
                const error =
                  (dataWeight && dataWeight.message) || response.status;
                return Promise.reject(error);
              }
              props.getCalfs();
              props.closeModal();
            })
            .catch((error) => {
              console.error("There was an error creating a weigth!", error);
            });
        }
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const handleColostrumMean = (mean) => {
    if (colostrumMean === mean) {
      setColostrumMean("");
    } else {
      setColostrumMean(mean);
    }
  };
  const handleFer = (event) => {
    setColostrumContent_fer(event.target.checked);
  };
  const handleSelenium = (event) => {
    setColostrumContent_selenium(event.target.checked);
  };
  const handleLocatim = (event) => {
    setColostrumContent_locatim(event.target.checked);
  };
  const handleVaccinated = (event) => {
    setVaccinated(event.target.checked);
  };
  const handleEcornage = (event) => {
    setEcornage(event.target.checked);
  };
  const handleCastration = (event) => {
    setCastration(event.target.checked);
  };
  return (
    <div className="flex flex-col">
      <div className="">
        <form onSubmit={handleSubmit} className="flex w-full flex-col text-sm">
          <div className="mb-6 mt-3 flex border-t border-stone-200 pt-6">
            {" "}
            <div className="w-1/3">
              <span className="font-medium">Informations générales</span>
            </div>
            <div className="flex w-2/3 flex-col">
              <label
                htmlFor="bdta_input"
                className="text-sm font-medium text-stone-700"
              >
                BDTA
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-stone-500 shadow-sm shadow-sm"
                type="text"
                value={bdta}
                onChange={(e) => setBdta(e.target.value)}
                placeholder="BDTA"
                name="bdta_input"
              ></input>

              <label
                htmlFor="name_input"
                className="text-sm font-medium text-stone-700"
              >
                Nom
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-stone-500 shadow-sm shadow-sm"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Nom"
                name="name_input"
              ></input>

              <label
                htmlFor="sex_input"
                className="text-sm font-medium text-stone-700"
              >
                Sexe
              </label>
              <select
                className="mb-3 w-fit rounded-md border border-stone-300 px-3 py-2 pr-12 shadow-sm"
                value={sex}
                onChange={(e) => setSex(e.target.value)}
                name="sex_input"
              >
                <option value="f">Femelle</option>
                <option value="m">Mâle</option>
              </select>
              <div
                className={
                  "my-3 flex flex-row " + (sex === "f" ? "hidden" : "")
                }
              >
                <div className="lex flex-row">
                  <input
                    className="form-checkbox mr-2 cursor-pointer rounded-sm"
                    type="checkbox"
                    id="castreCheckbox"
                    name="castreCheckbox"
                    checked={castration}
                    onChange={handleCastration}
                  />
                  <label htmlFor="castreCheckbox" className="cursor-pointer">
                    Castré
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-6 mt-3 flex border-t border-stone-200 pt-6">
            <div className="w-1/3">
              <span className="font-medium">Naissance</span>
            </div>
            <div className="flex w-2/3 flex-col">
              <div className="flex flex-row">
                <div className="w-1/2 pr-2">
                  <label
                    htmlFor="birthday_input"
                    className="text-sm font-medium text-stone-700"
                  >
                    Date de naissance
                  </label>
                  <input
                    className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-stone-500 shadow-sm shadow-sm"
                    type="date"
                    defaultValue={birthdate}
                    onChange={(e) => setBirthdate(e.target.value)}
                    placeholder="Date de naissance"
                    name="birthday_input"
                  ></input>
                </div>
                <div className="w-1/2 pl-2">
                  <label
                    htmlFor="birthtime_input"
                    className="text-sm font-medium text-stone-700"
                  >
                    Heure de naissance
                  </label>
                  <input
                    className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-stone-500 shadow-sm shadow-sm"
                    type="time"
                    value={birthtime}
                    onChange={(e) => setBirthtime(e.target.value)}
                    placeholder="Heure de naissance"
                    name="birthtime_input"
                  ></input>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex w-1/2 flex-col pr-2">
                  <label
                    htmlFor="vitality_input"
                    className="text-sm font-medium text-stone-700"
                  >
                    Vitalité
                  </label>
                  <select
                    className="mb-3 w-fit rounded-md border border-stone-300 px-3 py-2 pr-12 shadow-sm"
                    value={vitality}
                    onChange={(e) => setVitality(e.target.value)}
                    name="vitality_input"
                  >
                    <option value="vif">Vif</option>
                    <option value="apathique">Apathique</option>
                    <option value="mort">Mort</option>
                  </select>
                </div>
                <div className="w-1/2 pl-2">
                  <label
                    htmlFor="birthtype_input"
                    className="text-sm font-medium text-stone-700"
                  >
                    Déroulement de la naissance
                  </label>
                  <select
                    className="mb-3 w-fit rounded-md border border-stone-300 px-3 py-2 pr-12 shadow-sm"
                    value={birthtype}
                    onChange={(e) => setBirthtype(e.target.value)}
                    name="birthtype_input"
                  >
                    <option value="sans aide">Sans aide</option>
                    <option value="aide faible">Aide faible</option>
                    <option value="naissance difficile">
                      Naissance difficile
                    </option>
                  </select>
                </div>
              </div>
              <label
                htmlFor="weight_input"
                className="text-sm font-medium text-stone-700"
              >
                Poids à la naissance
              </label>
              <input
                className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-stone-500  shadow-sm"
                type="text"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
                placeholder="poids en Kg"
                name="weight_input"
              ></input>
            </div>
          </div>
          <div className="mb-6 mt-3 flex border-t border-stone-200 pt-6">
            <div className="w-1/3">
              <span className="font-medium">Apport</span>
            </div>
            <div className="flex w-2/3 flex-col">
              <div className="flex flex-row">
                <div className="w-1/2 pr-2">
                  <label
                    htmlFor="colostrum_input"
                    className="text-sm font-medium text-stone-700"
                  >
                    1er apport en colostrum
                  </label>
                  <div className="mb-3 flex items-center justify-between rounded-lg border border-stone-300 shadow-sm">
                    <input
                      className="w-11/12 border-none bg-transparent px-3 py-2 text-left text-stone-500"
                      type="text"
                      value={colostrum}
                      onChange={(e) => setColostrum(e.target.value)}
                      placeholder="Apport en colostrum"
                      name="colostrum_input"
                    ></input>
                    <div className="mr-3 w-1/12 text-stone-500">
                      <span className="font-fira italic">l</span>
                    </div>
                  </div>
                </div>
                <div className="w-1/2 pl-2">
                  <label
                    htmlFor="apporttime_input"
                    className="text-sm font-medium text-stone-700"
                  >
                    Heure de l'apport
                  </label>
                  <input
                    className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-stone-500 shadow-sm shadow-sm"
                    type="time"
                    value={apporttime}
                    onChange={(e) => setApporttime(e.target.value)}
                    placeholder="Heure de l'apport"
                    name="apporttime_input"
                  ></input>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex w-1/2 flex-col pr-2">
                  <label
                    htmlFor="colostrumMean_input"
                    className="mt-3 text-sm font-medium text-stone-700"
                  >
                    Apport colostrumMean
                  </label>
                  <select
                    className="mb-3 w-fit rounded-md border border-stone-300 px-3 py-2 pr-12 shadow-sm"
                    value={colostrumQuality}
                    onChange={(e) => setColostrumMean(e.target.value)}
                    name="colostrumMean_input"
                  >
                    <option value="Biberon">biberon</option>
                    <option value="Sonde">Sonde</option>
                  </select>
                </div>
                <div className="flex w-1/2 flex-col pl-2">
                  <label
                    htmlFor="colostrumSource_input"
                    className="mt-3 text-sm font-medium text-stone-700"
                  >
                    Apport
                  </label>
                  <select
                    className="mb-3 w-fit rounded-md border border-stone-300 px-3 py-2 pr-12 shadow-sm"
                    value={colostrumSource}
                    onChange={(e) => setColostrumSource(e.target.value)}
                    name="colostrumSource_input"
                  >
                    <option value="Maternel">Maternel</option>
                    <option value="Congelé">Congelé</option>
                    <option value="Autre">Autre</option>
                  </select>
                </div>
              </div>
              <div className="flex flex-row">
                <div className="flex w-1/2 flex-col">
                  <label
                    htmlFor="colostrumQuality_input"
                    className="mt-3 text-sm font-medium text-stone-700"
                  >
                    Apport colostrumquality
                  </label>
                  <select
                    className="mb-3 w-fit rounded-md border border-stone-300 px-3 py-2 pr-12 shadow-sm"
                    value={colostrumQuality}
                    onChange={(e) => setColostrumQuality(e.target.value)}
                    name="colostrumQuality_input"
                  >
                    <option value="Bon">Bon</option>
                    <option value="Moyen">Moyen</option>
                    <option value="Mauvais">Mauvais</option>
                  </select>
                </div>
                <div>
                  <div className="my-3 flex flex-row">
                    <input
                      className="form-checkbox mr-2 cursor-pointer rounded-sm"
                      type="checkbox"
                      id="ferRadioButton"
                      name="ferRadioButton"
                      checked={colostrumContent_fer}
                      onChange={handleFer}
                    />
                    <label htmlFor="ferRadioButton" className="cursor-pointer">
                      Fer
                    </label>
                  </div>
                  <div className="my-3 flex flex-row">
                    <input
                      className="form-checkbox mr-2 cursor-pointer rounded-sm"
                      type="checkbox"
                      id="seleniumRadioButton"
                      name="seleniumRadioButton"
                      checked={colostrumContent_selenium}
                      onChange={handleSelenium}
                    />
                    <label
                      htmlFor="seleniumRadioButton"
                      className="cursor-pointer"
                    >
                      Sélenium
                    </label>
                  </div>
                  <div className="my-3 flex flex-row items-center">
                    <input
                      className="form-checkbox mr-2 cursor-pointer rounded-sm"
                      type="checkbox"
                      id="locatimeRadioButton"
                      name="locatimeRadioButton"
                      checked={colostrumContent_locatim}
                      onChange={handleLocatim}
                    />
                    <label
                      htmlFor="locatimeRadioButton"
                      className="cursor-pointer"
                    >
                      Locatim
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 flex border-t border-stone-200">
            <div className="w-1/3">
              <span className="font-medium">Vaccin</span>
            </div>
            <div>
              <div className="my-3 flex flex-row">
                <div className="flex flex-row">
                  <input
                    className="form-checkbox mr-2 cursor-pointer rounded-sm"
                    type="checkbox"
                    id="vaccinatedCheckbox"
                    name="vaccinatedCheckbox"
                    checked={vaccinated}
                    onChange={handleVaccinated}
                  />
                  <label
                    htmlFor="vaccinatedCheckbox"
                    className="cursor-pointer"
                  >
                    Vacciné
                  </label>
                </div>
              </div>
              <div
                className={"my-3 flex flex-col " + (vaccinated ? "" : "hidden")}
              >
                <label
                  htmlFor="vaccine_input"
                  className="text-sm font-medium text-stone-700"
                >
                  Vaccin
                </label>
                <input
                  className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-stone-500 shadow-sm"
                  type="text"
                  value={vaccine}
                  onChange={(e) => setVaccine(e.target.value)}
                  name="vaccine_input"
                ></input>
              </div>{" "}
            </div>{" "}
          </div>
          <div className="mt-3 flex border-t border-stone-200">
            <div className="w-1/3">
              <span className="font-medium">Écornage</span>
            </div>
            <div className="w-2/3">
              <div className="my-3 flex flex-row">
                <div className="flex flex-row">
                  <input
                    className="form-checkbox mr-2 cursor-pointer rounded-sm"
                    type="checkbox"
                    id="ecornageCheckbox"
                    name="ecornageCheckbox"
                    checked={ecornage}
                    onChange={handleEcornage}
                  />
                  <label htmlFor="ecornageCheckbox" className="cursor-pointer">
                    Écornage
                  </label>
                </div>
              </div>
              <div
                className={"my-3 flex flex-col " + (ecornage ? "" : "hidden")}
              >
                <label
                  className="mb-3 text-sm font-medium text-stone-700"
                  htmlFor="ecornageDate_input"
                >
                  Date d'écornage
                </label>
                <input
                  className="mb-3 w-full rounded-lg border border-stone-300 px-3 py-2 text-stone-500 shadow-sm"
                  type="date"
                  value={ecornageDate}
                  onChange={(e) => setEcornageDate(e.target.value)}
                  placeholder="Date d'ecornage"
                  name="ecornageDate_input"
                ></input>
              </div>
            </div>
          </div>

          <div className="flex">
            <div className="mb-3 w-1/2 pr-2"></div>
            <div className="w-1/2 pl-2">
              <button
                type="submit"
                className="w-full rounded-lg border border-amber-500 bg-amber-400 px-3 py-2 font-semibold text-white shadow hover:shadow-inner"
              >
                Ajouter
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateCalfDesktop;
