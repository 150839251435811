import React from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, Fragment } from "react";
function SlideMenu(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = AuthService.getCurrentUser();
  const handleLogout = () => {
    AuthService.logout();
    navigate("/login");
  };
  return (
    <Fragment>
      <div
        className={
          "fixed top-0 right-0 z-40 flex h-full w-72 flex-col border-l border-r-stone-300 bg-white p-3 pt-5 transition-transform duration-200 ease-in-out " +
          (props.isDisplay ? "translate-x-0 shadow-xl" : " translate-x-full")
        }
      >
        {" "}
        <button className="" onClick={() => props.setDisplay()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="black"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
            />
          </svg>
        </button>
        <Link to="/">
          <div
            className={
              "mt-6 flex rounded-md py-2 px-1 " +
              (location.pathname === "/"
                ? "bg-amber-50 text-amber-700"
                : "text-stone-500")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
              />
            </svg>

            <span className="ml-3 font-semibold">Mes veaux</span>
          </div>
        </Link>
        <Link to="/nouveau">
          <div
            className={
              "mt-3 flex rounded-md py-2 px-1 " +
              (location.pathname === "/nouveau"
                ? "bg-amber-50 text-amber-700"
                : "text-stone-500")
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span className="ml-3 font-semibold">Nouveau veau</span>
          </div>
        </Link>
        <div
          className={currentUser.roles.includes("ROLE_ADMIN") ? "" : "hidden"}
        >
          <Link to="/admin">
            <div
              className={
                "mt-3 flex rounded-md py-2 px-1 " +
                (location.pathname === "/admin"
                  ? "bg-amber-50 text-amber-700"
                  : "text-stone-500")
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
                />
              </svg>

              <span className="ml-3 font-semibold">
                Panneau d'administration
              </span>
            </div>
          </Link>
        </div>
        <div className="grow"></div>
        <div className="mb-6 w-full border border-stone-100"></div>
        <div className="mb-3 flex items-center justify-between">
          <div className="flex justify-start">
            <div className="mr-3 flex h-10 w-10 items-center justify-center rounded-full bg-amber-100 text-lg font-medium text-amber-700">
              {currentUser.username.substring(0, 2).toUpperCase()}
            </div>
            <div className="flex flex-col">
              <span className="text-sm font-medium text-stone-800">
                {currentUser.username}
              </span>
              <span className="text-xs text-stone-700">
                {currentUser.email}
              </span>
            </div>
          </div>
          <button className="" onClick={() => handleLogout()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 text-stone-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
              />
            </svg>
          </button>
        </div>
      </div>
    </Fragment>
  );
}

export default SlideMenu;
