import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopBar from "./TopBar";
function DiseaseCreateDesktop(props) {
  const [start_date, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [end_date, setEndDate] = useState();
  const [cause, setCause] = useState("");
  const navigate = useNavigate();
  let { id } = useParams();
  const handleSubmit = (event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        start_date: start_date,
        end_date: end_date,
        cause: cause,
        fk_calf: props.calfID,
      }),
    };

    fetch(process.env.REACT_APP_API_URL + "/health", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        props.closeModal();
        props.getDiseases();
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    }
  }, []);
  return (
    <div>
      <div className="">
        <div className="mb-3 flex flex-col">
          <form onSubmit={handleSubmit} className="flex w-full flex-col">
            <label
              htmlFor="start_date_input"
              className="text-sm font-medium text-stone-700"
            >
              Date de début de la maladie
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
              type="date"
              defaultValue={new Date().toISOString().substring(0, 10)}
              onChange={(e) => setStartDate(e.target.value)}
              placeholder="Date du début de la maladie"
              name="start_date_input"
            ></input>
            <label
              htmlFor="end_date_input"
              className="text-sm font-medium text-stone-700"
            >
              Date de fin de la maladie
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
              type="date"
              value={end_date}
              onChange={(e) => setEndDate(e.target.value)}
              placeholder="Date de fin de la maladie"
              name="end_date_input"
            ></input>
            <label
              htmlFor="weight_input"
              className="text-sm font-medium text-stone-700"
            >
              Cause
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
              type="text"
              value={cause}
              onChange={(e) => setCause(e.target.value)}
              placeholder="Cause de la maladie"
              name="weight_input"
            ></input>
            <div className="mt-3 flex justify-between">
              <button
                type="button"
                className="rounded-md bg-stone-100 px-3 py-2 text-sm font-medium text-stone-900 hover:bg-stone-200"
                onClick={props.closeModal}
              >
                Annuler
              </button>
              <button
                type="submit"
                className="rounded-lg bg-amber-400 px-5 py-2 text-sm font-medium text-white"
              >
                Ajouter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DiseaseCreateDesktop;
