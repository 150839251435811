import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import WeightChart from "../components/WeightChart";
import WeightChart2 from "../components/veauHub/WeightChart";
import { Dialog, Transition } from "@headlessui/react";
import WeightPreview from "./WeightPreview";
import CreateWeightDesktop from "./CreateWeightDesktop";
import WeightPreviewDesktop from "./WeightPreviewDesktop";
import UpdateWeightDesktop from "./UpdateWeightDesktop";
function Weights(props) {
  const currentUser = AuthService.getCurrentUser();
  const [calf, setCalf] = useState({});
  const [weights, setWeights] = useState([]);
  const [weightsChart, setWeightsChart] = useState([]);
  const [birthWeight, setBirthWeight] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  let [isCreateModalOpen, setisCreateModaleOpen] = useState(false);
  let [isUpdateModalOpen, setisUpdateModalOpen] = useState(false);
  let [selectedWeight, setSelectedWeight] = useState(0);
  function closeCreateModal() {
    setisCreateModaleOpen(false);
  }

  function openCreateModal() {
    setisCreateModaleOpen(true);
  }
  function closeUpdateModal() {
    setisUpdateModalOpen(false);
  }

  function openUpdateModal() {
    setisUpdateModalOpen(true);
  }
  let { calfId } = useParams();
  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs/" + calfId, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setCalf(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const getWeights = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    fetch(
      process.env.REACT_APP_API_URL + "/weight/calf/" + calfId,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setWeights(data);
        let chartData = [];
        data.forEach((weight) => {
          chartData.push({ x: new Date(weight.date), y: weight.weight });
          if (weight.date === props.calf.birthdate)
            setBirthWeight(weight.weight);
        });
        setWeightsChart(chartData);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const refresh = () => {
    setWeights([]);
    getCalf();
    getWeights();
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getCalf();
      getWeights();
    }
  }, []);
  if (isLoading) {
    return <div></div>;
  }
  return (
    <div className=" md:bg-white">
      <div className={weights.length === 0 ? "hidden" : ""}>
        <WeightChart2 weights={weightsChart}></WeightChart2>
        <div className={"flex flex-row justify-end md:hidden"}>
          <Link to={"/poids/" + calfId}>
            <span className="font-medium text-amber-500">Ajouter un poids</span>
          </Link>
        </div>
        <div
          className={
            "mt-3 w-full overflow-visible rounded-lg border-stone-200 bg-white md:mt-6 md:border md:shadow "
          }
        >
          <div className="mb-3 hidden justify-between rounded-t-md border-b border-stone-200  p-6 text-stone-800 md:flex">
            <span className="text-lg font-semibold">Liste des poids</span>
            <button
              onClick={openCreateModal}
              className="flex items-center rounded-lg border border-stone-200 px-3 py-2 text-sm text-stone-800 shadow transition-all duration-100 hover:-translate-y-[2px] hover:shadow-lg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="mr-2 h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>

              <span>Ajouter</span>
            </button>
          </div>
          {weights.map((weight, index) => (
            <WeightPreviewDesktop
              key={"weight_" + index}
              calfID={calfId}
              weightID={weight.pk_weights}
              index={index}
              listLength={weights.length}
              setisUpdateModalOpen={setisUpdateModalOpen}
              setSelectedWeight={setSelectedWeight}
            ></WeightPreviewDesktop>
          ))}
        </div>
      </div>
      <div
        className={
          "mt-3 flex flex-col items-center justify-center " +
          (weights.length > 0 ? "hidden" : "")
        }
      >
        <div>
          <span className="font-semibold">Aucune poids enregistré</span>
        </div>
        <p className="my-3 text-center text-sm text-stone-400">
          Vous n'avez encore enregistré aucun poids pour ce veau. Cliquer sur
          "Ajouter un poid" pour commencer !
        </p>
        <Link to={"/poids/" + calfId}>
          <button className="rounded-lg bg-amber-400 py-1 px-3 text-white">
            Ajouter un poids
          </button>
        </Link>
      </div>
      <Transition appear show={isCreateModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeCreateModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Ajouter un poids
                  </Dialog.Title>
                  <div className="mt-2">
                    <CreateWeightDesktop
                      closeModal={closeCreateModal}
                      refreshComponent={refresh}
                      calfID={calfId}
                    ></CreateWeightDesktop>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isUpdateModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeUpdateModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Ajouter un poids
                  </Dialog.Title>
                  <div className="mt-2">
                    <UpdateWeightDesktop
                      selectedWeight={selectedWeight}
                      closeModal={closeUpdateModal}
                      calfID={calfId}
                      refreshComponent={refresh}
                    ></UpdateWeightDesktop>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default Weights;
