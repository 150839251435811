import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
function Disease() {
  const [disease, setDisease] = useState({});
  const [calf, setCalf] = useState({});
  const navigate = useNavigate();
  let { calfId, id } = useParams();
  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs/" + calfId, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setCalf(data);
      })
      .catch((error) => {
        console.error("There was an error getting a calf!", error);
      });
  };
  const getDisease = () => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/health/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setDisease(data);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/health/" + id, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        navigate("/veaux/" + calfId, { state: { from: "health" } });
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      getDisease();
      getCalf();
    }
  }, []);
  return (
    <div>
      <TopBar link={"/veaux/" + calfId} state={{ from: "health" }}></TopBar>
      <div className="flex md:w-[800px] mb-3 ml-3">
        <div className="flex justify-center items-center px-3 py-1 bg-stone-200/60 rounded-lg">
          <Link to="/">
            <span className="font-semibold text-stone-700 text-sm">
              Mes Veaux
            </span>
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 text-stone-300 mr-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
          <Link to={"/veaux/" + calfId} state={{ from: "health" }}>
            <span className=" font-semibold text-stone-700 text-sm">
              {calf.name}
            </span>
          </Link>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4 text-stone-300 mr-1"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
          <span className="text-stone-400 text-sm">
            {new Date(disease.start_date).toLocaleString("default", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </span>
        </div>
      </div>
      <div className="bg-white rounded-lg mt-3 mx-3">
        <div className="flex flex-col ml-3">
          <div className="flex justify-between items-center border-b">
            <span className="py-2 text-sm">Date de début</span>
            <span className="text-stone-400 text-sm mr-3">
              {new Date(disease.start_date).toLocaleString("default", {
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </span>
          </div>
          <div className="flex justify-between items-center border-b">
            <span className="py-2 text-sm">Date de fin</span>
            <span className="text-stone-400 text-sm mr-3">
              {disease.end_date === null
                ? ""
                : new Date(disease.end_date).toLocaleString("default", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                  })}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="py-2 text-sm">Cause</span>
            <span className="text-stone-400 text-sm mr-3">{disease.cause}</span>
          </div>
        </div>
      </div>
      <div className="px-3 mt-3">
        <Link to={"/modifier_maladie/" + calfId + "/" + id}>
          <button className="bg-amber-400 rounded-lg text-white shadow font-semibold py-2 w-full">
            Modifier
          </button>
        </Link>
      </div>
      <div className="px-3 mt-3">
        <button
          className="bg-white rounded-lg text-red-500 shadow font-semibold py-2 w-full"
          onClick={() => handleDelete()}
        >
          Supprimer
        </button>
      </div>
    </div>
  );
}

export default Disease;
