import React from "react";
import { useState, useEffect } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams } from "react-router-dom";
import UpdateDiseaseDesktop from "./UpdateDiseaseDesktop";
function UpdateVaccinDesktop(props) {
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [name, setName] = useState("");
  const navigate = useNavigate();
  let { calfId } = useParams();
  const handleSubmit = (event) => {
    // prevents the submit button from refreshing the page
    event.preventDefault();

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        pk_vaccine: props.selectedVaccine.pk_vaccine,
        fk_calf: calfId,
        name: name,
        date: new Date(date).toISOString().substring(0, 10),
      }),
    };

    fetch(
      process.env.REACT_APP_API_URL +
        "/vaccine/" +
        props.selectedVaccine.pk_vaccine,
      requestOptions
    )
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        props.getVaccine();
        props.closeModal();
      })
      .catch((error) => {
        console.error("There was an error updating vaccin!", error);
      });
  };
  useEffect(() => {
    //Runs only on the first render
    setName(props.selectedVaccine.name);
    setDate(
      new Date(props.selectedVaccine.date).toISOString().substring(0, 10)
    );
  }, []);
  return (
    <div>
      <div className="rounded-lg bg-white">
        <div className="mb-3 flex flex-col">
          <form onSubmit={handleSubmit} className="flex w-full flex-col">
            <label
              htmlFor="date_input"
              className="text-sm font-medium text-stone-700"
            >
              Date du vaccin
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
              type="date"
              defaultValue={new Date().toISOString().substring(0, 10)}
              onChange={(e) => setDate(e.target.value)}
              placeholder="Date du vaccin"
              name="date_input"
            ></input>

            <label
              htmlFor="name_input"
              className="text-sm font-medium text-stone-700"
            >
              Nom
            </label>
            <input
              className="mb-3 w-full rounded-lg border border-stone-300 bg-stone-100 p-3 text-stone-500"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nom du vaccin"
              name="name_input"
            ></input>
            <button
              type="submit"
              className="my-3 mb-3 w-full rounded-lg bg-amber-400 p-3 font-medium text-white"
            >
              Modifier
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdateVaccinDesktop;
