import React from "react";
import { useState, useEffect, Fragment } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Weights from "../components/Weights";
import Data from "../components/Data";
import DiseaseList from "../components/DiseaseList";
import TopBar from "../components/TopBar";
import Vaccines from "../components/Vaccines";
import DataDesktop from "../components/Data_desktop";
import DiseaseListDesktop from "../components/DiseaseListDesktop";
import VaccineListDesktop from "../components/VaccineListDesktop";
import UpdateDataDesktop from "../components/UpdateDataDesktop";
function Calf() {
  const currentUser = AuthService.getCurrentUser();
  const [calf, setCalf] = useState({});
  const [view, setView] = useState("weights");
  const [isLoading, setLoading] = useState(true);
  const [notFound, setNotFound] = useState(false);
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { from } = location.state || "weights";
  let { calfId } = useParams();
  let [isUpdateDataOpen, setIsUpdateDataOpen] = useState(false);
  function closeUpdateDataModal() {
    setIsUpdateDataOpen(false);
  }

  function openUpdateDataModal() {
    setIsUpdateDataOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const getCalf = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-access-token": currentUser.accessToken,
      },
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs/" + calfId, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());
        console.log(response);
        // check for error response
        if (response.status === 404) {
          setNotFound(true);
          setLoading(false);
        }
        // check for error response
        if (response.status === 401) {
          navigate("/login");
        }
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        setCalf(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error creating a calf!", error);
      });
  };
  const handleDownload = () => {
    setDownloadLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/print/" + calfId, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        const pdfWindow = window.open();
        pdfWindow.location.href = process.env.REACT_APP_API_BASE_URL + data.url;
        setDownloadLoading(false);
      })
      .catch((error) => {
        console.error("There was an error downloading a file!", error);
        setDownloadLoading(false);
      });
  };
  const handleDelete = () => {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(process.env.REACT_APP_API_URL + "/calfs/" + calfId, requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        navigate("/");
      })
      .catch((error) => {
        console.error("There was an error deleting a calf!", error);
      });
  };
  const refreshCalf = () => {
    setLoading(true);
    setCalf({});
    getCalf();
  };
  const changeView = (view) => {
    setView(view);
  };

  useEffect(() => {
    //Runs only on the first render
    const currentUser = AuthService.getCurrentUser();
    if (!currentUser) {
      navigate("/login");
    } else {
      if (from) {
        setView(from);
      }
      getCalf();
    }
  }, []);
  if (isLoading) {
    return <div>loading</div>;
  } else if (notFound) {
    return (
      <div>
        <TopBar link="/"></TopBar>
        <div>
          <p>Nous n'avons pas pu trouver ce veau dans votre liste de veau.</p>
        </div>
      </div>
    );
  }
  return (
    <div>
      <TopBar link="/"></TopBar>
      <div className="md:flex md:flex-col md:items-center">
        <div className="mb-3 ml-3 flex md:w-[1400px]">
          <div className="flex items-center justify-center rounded-lg bg-stone-200/60 px-3 py-1">
            <Link to="/">
              <span className="text-sm font-medium text-stone-500">
                Mes Veaux
              </span>
            </Link>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mr-1 h-4 w-4 text-stone-300"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
            <span className="text-sm font-semibold text-stone-700">
              {calf.name}
            </span>
          </div>
        </div>
        <div className="px-3 md:w-[1400px] md:bg-white">
          <div className="mb-3 rounded-lg bg-white p-1">
            <div className="mb-3 ml-2 mt-1 flex justify-between">
              <div>
                <span className="text-2xl font-bold">{calf?.name}</span>
              </div>
              <div className="hidden md:flex">
                <button
                  onClick={() => openModal()}
                  className="mr-3 flex items-center justify-center rounded-md border border-stone-300 bg-white px-3 py-2 text-sm text-stone-800 shadow transition-all duration-100 hover:shadow-inner"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mr-3 h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                  Supprimer
                </button>
                <button
                  onClick={() => openUpdateDataModal()}
                  className="mr-3 flex items-center rounded-md border border-stone-300 bg-white px-3 py-2 text-sm text-stone-800 shadow transition-all duration-100 hover:shadow-inner"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="mr-3 h-4 w-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                    />
                  </svg>
                  Modifier
                </button>
                <button
                  onClick={() => handleDownload()}
                  disabled={isDownloadLoading ? true : false}
                  className="flex rounded-md border border-amber-500 bg-amber-400 px-3 py-2 text-sm text-white shadow transition-all duration-100 hover:shadow-inner"
                >
                  <div
                    className={
                      "flex flex-row items-center justify-center " +
                      (isDownloadLoading ? "" : "hidden")
                    }
                  >
                    <svg
                      className="mr-1 h-4 w-4 animate-spin text-amber-600"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="text-amber-200"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <span>Traitement...</span>
                  </div>
                  <div className={isDownloadLoading ? "hidden" : "flex"}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="mr-3 h-4 w-4"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                    <span>Rapport</span>
                  </div>
                </button>
              </div>
            </div>

            <div className="flex flex-row justify-between rounded-lg bg-stone-100 p-1 md:hidden">
              <div
                className={
                  "cursor-pointer px-3 py-2 " +
                  (view === "weights"
                    ? "rounded-md bg-white font-medium text-amber-500 shadow"
                    : "text-stone-500")
                }
                onClick={(e) => changeView("weights")}
              >
                <span>Poids</span>
              </div>
              <div
                className={
                  "cursor-pointer px-3 py-2 " +
                  (view === "health"
                    ? "rounded-md bg-white font-medium text-amber-500 shadow"
                    : "text-stone-500")
                }
                onClick={(e) => changeView("health")}
              >
                <span>Maladies</span>
              </div>
              <div
                className={
                  "cursor-pointer px-3 py-2 " +
                  (view === "vaccin"
                    ? "rounded-md bg-white font-medium text-amber-500 shadow"
                    : "text-stone-500")
                }
                onClick={(e) => changeView("vaccin")}
              >
                <span>Vaccins</span>
              </div>
              <div
                className={
                  "cursor-pointer px-3 py-2 " +
                  (view === "data"
                    ? "rounded-md bg-white font-medium text-amber-500 shadow"
                    : "text-stone-500")
                }
                onClick={(e) => changeView("data")}
              >
                <span>Données</span>
              </div>
            </div>
          </div>
          <div className="mb-6 hidden w-full border-b border-stone-200 md:block"></div>
          <div className="hidden md:flex">
            <div className="my-3 mr-8 w-1/3">
              <DataDesktop></DataDesktop>
              <VaccineListDesktop></VaccineListDesktop>
              <DiseaseListDesktop></DiseaseListDesktop>
            </div>
            <div className="w-full md:w-2/3">
              <Weights calf={calf}></Weights>
            </div>
          </div>
          <div className="w-full md:hidden">
            {view === "weights" ? (
              <Weights calf={calf}></Weights>
            ) : view === "health" ? (
              <DiseaseList calfID={calfId}></DiseaseList>
            ) : view === "vaccin" ? (
              <Vaccines calfID={calfId}></Vaccines>
            ) : (
              <Data calfID={calfId} openModal={openModal}></Data>
            )}
          </div>
        </div>
      </div>
      <Transition appear show={isUpdateDataOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={closeUpdateDataModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Modifier les informations
                  </Dialog.Title>
                  <div className="mt-2">
                    <UpdateDataDesktop
                      closeModal={closeUpdateDataModal}
                      getCalf={refreshCalf}
                      calfID={calfId}
                    ></UpdateDataDesktop>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Etes-vous sûr de vouloir supprimer {calf.name} ?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Tous les données concernant {calf.name} seront supprimer
                      et vous ne pourrez plus les récupérer.
                    </p>
                  </div>

                  <div className="mt-4 flex justify-between">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-stone-100 px-4 py-2 text-sm font-medium text-stone-900 hover:bg-stone-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-stone-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={(e) => handleDelete()}
                    >
                      Oui, supprimer
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default Calf;
